import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

import { Alert, LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Grid2 from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Models } from 'types';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { getNumbersRegex } from '../../helpers/validations';
import { AddTargetAccountForm } from '../admin/admin.types';
import { bankAccountActions } from './bank-account-slice';

const accountTypes = {
  checking_account: 'Conta Corrente',
  deposit_account: 'Conta Depósito',
  guaranteed_account: 'Conta de Garantia',
  investment_account: 'Conta de Investimento',
  payment_account: 'Conta de Pagamento',
  saving_account: 'Conta Poupança',
};

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

export default function TargetBankAccountForm() {
  const dispatch = useAppDispatch();
  const { bankAccount, targetBankAccount, company } = useAppSelector(
    (state) => state.app
  );
  const [targetAccountPixKey, setTargetAccountPixKey] = useState('');
  const [dataToSave, setDataToSave] = useState<
    AddTargetAccountForm | undefined
  >();

  const [
    isSaveTargetBankAccountConfirmationOpen,
    setIsSaveTargetBankAccountConfirmationOpen,
  ] = useState(false);
  const { searchPixKeyResponse, isSearchingPixKey, isSavingTargetAccount } =
    useAppSelector((state) => state.bankAccount);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<AddTargetAccountForm>({
    defaultValues: {
      account: '',
      accountDigit: '',
      accountType: 'checking_account',
      branch: '',
    },
  });

  const onSubmit = (data: AddTargetAccountForm) => {
    setDataToSave(data);
    setIsSaveTargetBankAccountConfirmationOpen(true);
  };

  const handleConfirmSaveTargetBankAccount = () => {
    if (!searchPixKeyResponse?.resultPayload) return;
    if (!bankAccount) return;
    if (!company) return;
    if (!dataToSave) return;

    const { resultPayload } = searchPixKeyResponse;
    const data: Models.SaveTargetBankAccountPayload = {
      branch: dataToSave.branch,
      account: dataToSave.account,
      accountDigit: dataToSave.accountDigit,
      accountType: dataToSave.accountType,
      bankCode: resultPayload.bank_code,
      bankName: resultPayload.financial_institution,
      ispb: resultPayload.ispb,
      documentType: bankAccount.documentType,
      documentNumber: bankAccount.documentNumber,
      ownerName: resultPayload.owner_name,
      createdAt: dayjs().toDate().toISOString(),
      pixKey: resultPayload.pix_key,
    };

    if (targetBankAccount) {
      const payload: Models.UpdateTargetBankAccountPayload = {
        id: targetBankAccount.id,
        data,
      };
      dispatch(bankAccountActions.updateTargetBankAccount(payload));
    } else {
      dispatch(bankAccountActions.createTargetBankAccount(data));
    }
    setIsSaveTargetBankAccountConfirmationOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap={2}>
        <Stack direction="row" alignItems="center" gap={1}>
          <TextField
            id="name"
            label="Chave PIX"
            type="text"
            value={targetAccountPixKey}
            onChange={(e) => setTargetAccountPixKey(e.target.value)}
          />
          <IconButton
            disabled={isSearchingPixKey || targetAccountPixKey.trim() === ''}
            onClick={() =>
              dispatch(bankAccountActions.searchPixKey(targetAccountPixKey))
            }
          >
            <SearchIcon />
          </IconButton>
        </Stack>
        {isSearchingPixKey ? (
          <Stack direction="column" gap={2} alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {searchPixKeyResponse && searchPixKeyResponse.result === 'fail' && (
              <Alert variant="outlined" severity="error">
                Chave não encontrada.
              </Alert>
            )}
            {searchPixKeyResponse &&
              searchPixKeyResponse.result === 'success' &&
              searchPixKeyResponse.resultPayload && (
                <Grid2 container gap={2}>
                  <Grid2 sm={12}>
                    <Detail
                      label="Banco"
                      value={
                        searchPixKeyResponse.resultPayload.financial_institution
                      }
                    />
                  </Grid2>
                  <Grid2 sm={12}>
                    <Detail
                      label="ISPB"
                      value={format(
                        searchPixKeyResponse.resultPayload.ispb,
                        'ispb'
                      )}
                    />
                  </Grid2>
                  <Grid2 sm={12}>
                    <Detail
                      label="Documento"
                      value={
                        searchPixKeyResponse.resultPayload
                          .owner_masked_document_number
                      }
                    />
                  </Grid2>
                  <Grid2 sm={12} md={5}>
                    <TextField
                      fullWidth
                      id="name"
                      label="Agência (sem dígito)"
                      type="text"
                      {...register('branch', {
                        required: 'Obrigatório',
                        maxLength: { value: 4, message: 'Valor inválido' },
                        pattern: {
                          value: getNumbersRegex(),
                          message: 'Caracteres especiais não são aceitos',
                        },
                      })}
                      error={!!errors?.branch}
                      helperText={errors?.branch?.message}
                    />
                  </Grid2>
                  <Grid2 sm={12} md={3}>
                    <TextField
                      fullWidth
                      id="account"
                      label="Número da Conta"
                      type="text"
                      {...register('account', {
                        required: 'Obrigatório',
                        pattern: {
                          value: getNumbersRegex(),
                          message: 'Caracteres especiais não são aceitos',
                        },
                      })}
                      error={!!errors?.account}
                      helperText={errors?.account?.message}
                    />
                  </Grid2>
                  <Grid2 sm={12} md={3}>
                    <TextField
                      fullWidth
                      id="accountDigit"
                      label="Dígito"
                      type="text"
                      {...register('accountDigit', {
                        required: 'Obrigatório',
                        maxLength: { value: 1, message: 'Valor inválido' },
                        pattern: {
                          value: getNumbersRegex(),
                          message: 'Valor inválido',
                        },
                      })}
                      error={!!errors?.accountDigit}
                      helperText={errors?.accountDigit?.message}
                    />
                  </Grid2>
                  <Grid2 sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="account-type-label">
                        Tipo de Conta
                      </InputLabel>
                      <Select
                        labelId="account-type-label"
                        id="demo-simple-select-standard"
                        label="Tipo de Conta"
                        defaultValue="checking_account"
                        {...register('accountType')}
                      >
                        {Object.entries(accountTypes).map(([value, label]) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid2>
                </Grid2>
              )}
          </>
        )}
        <LoadingButton
          disabled={
            isSearchingPixKey || searchPixKeyResponse?.result !== 'success'
          }
          loadingPosition="start"
          startIcon={<SaveIcon />}
          loading={isSavingTargetAccount}
          variant="outlined"
          type="submit"
          // onClick={handleSaveTargetBankAccountClick}
        >
          Salvar
        </LoadingButton>
      </Stack>
      <ConfirmationDialog
        open={isSaveTargetBankAccountConfirmationOpen}
        title="Confirmação"
        isLoading={isSavingTargetAccount}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsSaveTargetBankAccountConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Salvar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleConfirmSaveTargetBankAccount,
            },
          },
        ]}
      >
        Deseja salvar os dados da conta?
      </ConfirmationDialog>
    </form>
  );
}
