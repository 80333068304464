import { PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { fork, put, select, take } from 'redux-saga/effects';
import { Models } from 'types';
import { WebCompanyHelpers } from '../../data-helpers/WebCompanyHelpers';
import { WebPaymentRequestDetailHelpers } from '../../data-helpers/WebPaymentRequestDetailHelpers';
import { WebRetailerCompanyHelpers } from '../../data-helpers/WebRetailerCompanyHelpers';
import { WebRetailerPaymentRequestHelpers } from '../../data-helpers/WebRetailerPaymentRequestHelpers';
import { WebRetailerReceivableHelpers } from '../../data-helpers/WebRetailerReceivableHelpers';
import { WebUserHelpers } from '../../data-helpers/WebUserHelpers';
import { appActions } from '../app/app-slice';
import { AppState } from '../app/app.types';
import { notifierActions } from '../notifier/notifier-slice';
import { retailerActions } from './retailer.slice';
import { LoadReceivablesRequest } from './retailer.types';

function* watchLoadPaymentRequests() {
  while (true) {
    const {
      payload,
    }: PayloadAction<Models.LoadPaymentRequestsForRetailerRequest> = yield take(
      retailerActions.loadPaymentRequests
    );

    yield put(retailerActions.setIsLoadingPaymentRequests(true));
    const { company }: AppState = yield select((state) => state.app);
    try {
      if (!company?.id) throw new Error('Empresa não encontrada 1');
      yield fork(loadSuppliers);

      const paymentRequests: Models.PaymentRequest[] =
        yield WebRetailerPaymentRequestHelpers.list(payload);

      yield put(retailerActions.setPaymentRequests(paymentRequests));
      yield put(retailerActions.setIsLoadingPaymentRequests(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(retailerActions.setIsLoadingPaymentRequests(false));
    }
  }
}

function* watchLoadReceivables() {
  while (true) {
    const {
      payload: { startDate, endDate },
    }: PayloadAction<LoadReceivablesRequest> = yield take(
      retailerActions.loadReceivables
    );
    yield put(retailerActions.setIsLoadingReceivables(true));
    try {
      const receivables: Models.Receivable[] =
        yield WebRetailerReceivableHelpers.list(startDate, endDate);

      yield put(retailerActions.setReceivables(receivables));
      // const retailerPaymentRequests: Models.PaymentRequest[] =
      //   yield WebPaymentRequestHelpers.list(
      //     ['pendente'],
      //     '19700101',
      //     '21991231',
      //     'idRetailer',
      //     idRetailer
      //   );
      // yield put(retailerActions.setPaymentRequests(retailerPaymentRequests));

      // const retailerPaymentRequestDetails: Models.PaymentRequestDetail[] =
      //   yield WebPaymentRequestDetailHelpers.list(
      //     [],
      //     '19700101',
      //     '21991231',
      //     'idRetailer',
      //     idRetailer
      //   );
      // yield put(
      //   retailerActions.setPaymentRequestDetails(retailerPaymentRequestDetails)
      // );

      yield put(retailerActions.setIsLoadingReceivables(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(retailerActions.setIsLoadingReceivables(false));
    }
  }
}

function* loadSuppliers() {
  try {
    const suppliers: Models.Company[] = yield WebRetailerCompanyHelpers.list();
    yield put(retailerActions.setSuppliers(suppliers));
  } catch (e) {
    yield put(appActions.setError((e as Error).message));
  }
}

function* loadSupplier(idSupplier: string) {
  try {
    yield put(retailerActions.setIsLoadingSelectedSupplier(true));
    const supplier: Models.Company = yield WebCompanyHelpers.getById(
      idSupplier
    );

    yield put(retailerActions.setSelectedSupplier(supplier));
    if (supplier.idOwner) {
      const user: Models.User = yield WebUserHelpers.getById(supplier.idOwner);
      yield put(retailerActions.setSelectedSupplierOwner(user));
    }
    yield put(retailerActions.setIsLoadingSelectedSupplier(false));
  } catch (e) {
    yield put(appActions.setError((e as Error).message));
    yield put(retailerActions.setIsLoadingSelectedSupplier(false));
  }
}

function* watchLoadSelectedPaymentRequest() {
  while (true) {
    const { payload: idPaymentRequest }: PayloadAction<string> = yield take(
      retailerActions.loadSelectedPaymentRequest
    );
    yield put(retailerActions.setIsLoadingSelectedPaymentRequest(true));
    try {
      const { company }: AppState = yield select((state) => state.app);
      // console.log('company: ', company, company?.id);
      if (!company?.id) throw new Error('Empresa não encontrada 2');
      // console.log('passou');

      yield fork(loadSuppliers);

      const paymentRequest: Models.PaymentRequest | undefined =
        yield WebRetailerPaymentRequestHelpers.getById(idPaymentRequest);
      if (!paymentRequest) throw new Error('Pagamento não encontrado');

      let confirmedByName: string | undefined = undefined;
      let confirmedByEmail: string | undefined = undefined;
      let rejectedByName: string | undefined = undefined;
      let rejectedByEmail: string | undefined = undefined;
      if (paymentRequest.confirmedBy) {
        const user: Models.User = yield WebUserHelpers.getById(
          paymentRequest.confirmedBy
        );
        confirmedByName = user.name;
        confirmedByEmail = user.email;
      }
      if (paymentRequest.rejectedBy) {
        const user: Models.User = yield WebUserHelpers.getById(
          paymentRequest.rejectedBy
        );
        rejectedByName = user.name;
        rejectedByEmail = user.email;
      }

      yield put(
        retailerActions.setSelectedPaymentRequest({
          ...paymentRequest,
          confirmedByName,
          confirmedByEmail,
          rejectedByName,
          rejectedByEmail,
        })
      );

      const details: Models.PaymentRequestDetail[] =
        yield WebPaymentRequestDetailHelpers.list(
          [],
          '19700101',
          '21991231',
          'idPaymentRequest',
          paymentRequest.id
        );
      yield put(retailerActions.setSelectedPaymentRequestDetails(details));

      const { idSupplier } = paymentRequest;
      yield fork(loadSupplier, idSupplier);
      yield put(retailerActions.setIsLoadingSelectedPaymentRequest(false));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(retailerActions.setIsLoadingSelectedPaymentRequest(false));
    }
  }
}

function* watchConfirmPayment() {
  while (true) {
    const {
      payload: { idPaymentRequest, navigate },
    }: PayloadAction<{
      idPaymentRequest: string;
      navigate: NavigateFunction;
    }> = yield take(retailerActions.confirmPayment);
    yield put(retailerActions.setIsApprovingPaymentRequest(true));

    try {
      yield WebRetailerPaymentRequestHelpers.confirm(idPaymentRequest);
      yield put(retailerActions.setIsApprovingPaymentRequest(false));
      yield put(
        notifierActions.enqueue({
          message: 'Pagamento aprovado com sucesso.',
          options: {
            variant: 'success',
          },
        })
      );
      yield navigate(`/varejista-pagamentos`);
    } catch (e) {
      console.error(e);
      yield put(appActions.setError((e as Error).message));
      yield put(retailerActions.setIsApprovingPaymentRequest(false));
    }
  }
}

function* watchRejectPayment() {
  while (true) {
    const {
      payload: { idPaymentRequest, navigate, rejectedReason },
    }: PayloadAction<{
      idPaymentRequest: string;
      navigate: NavigateFunction;
      rejectedReason: string;
    }> = yield take(retailerActions.rejectPayment);
    yield put(retailerActions.setIsApprovingPaymentRequest(true));

    try {
      yield WebRetailerPaymentRequestHelpers.reject(
        idPaymentRequest,
        rejectedReason
      );
      yield put(retailerActions.setIsApprovingPaymentRequest(false));
      yield put(
        notifierActions.enqueue({
          message: 'Solicitação de pagamento rejeitada.',
          options: {
            variant: 'success',
          },
        })
      );
      yield navigate(`/varejista-pagamentos`);
    } catch (e) {
      console.error(e);
      yield put(appActions.setError((e as Error).message));
      yield put(retailerActions.setIsApprovingPaymentRequest(false));
    }
  }
}

export default [
  watchLoadPaymentRequests,
  watchLoadReceivables,
  watchLoadSelectedPaymentRequest,
  watchConfirmPayment,
  watchRejectPayment,
];
