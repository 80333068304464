import { PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { put, select, take } from 'redux-saga/effects';
import { Models } from 'types';
import { WebDocumentHelpers } from '../../data-helpers/WebDocumentHelpers';
import { WebOnboardingHelpers } from '../../data-helpers/WebOnboardingHelpers';
import { OnboardingForm, SupplierOnboardingForm } from '../../types';
import { appActions } from '../app/app-slice';
import { AppState } from '../app/app.types';
import { onboardingActions } from './onboarding-slice';

function* watchCompleteRetailerOnboarding() {
  while (true) {
    const {
      payload: {
        data,
        navigate,
        mainContactSelfieWithDocFile,
        mainContactDocPictureFile,
        mainContactDocPictureBackFile,
        companyConstitutionDocFile,
      },
    }: PayloadAction<{
      data: OnboardingForm;
      mainContactSelfieWithDocFile: File;
      mainContactDocPictureFile: File;
      mainContactDocPictureBackFile?: File;
      companyConstitutionDocFile?: File;
      navigate: NavigateFunction;
    }> = yield take(onboardingActions.completeRetailerOnboarding);
    yield put(appActions.startLoading());
    const { dbUser }: AppState = yield select((state) => state.app);

    try {
      if (!dbUser) throw new Error('Erro ao completar cadastro');

      let docIdMainContactSelfieWithDoc = '';
      let docIdMainContactDocPicture = '';
      let docIdCompanyConstitutionDoc = '';
      let docIdMainContactDocPictureBack = '';

      if (mainContactSelfieWithDocFile) {
        docIdMainContactSelfieWithDoc = yield WebDocumentHelpers.upload(
          mainContactSelfieWithDocFile
        );
      }
      if (mainContactDocPictureFile) {
        docIdMainContactDocPicture = yield WebDocumentHelpers.upload(
          mainContactDocPictureFile
        );
      }
      if (mainContactDocPictureBackFile) {
        docIdMainContactDocPictureBack = yield WebDocumentHelpers.upload(
          mainContactDocPictureBackFile
        );
      }
      if (companyConstitutionDocFile) {
        docIdCompanyConstitutionDoc = yield WebDocumentHelpers.upload(
          companyConstitutionDocFile
        );
      }

      const dataToSave: Models.WebCompleteRetailerOnboardingRequest = {
        idCompany: dbUser.idCompany,
        idOwner: dbUser.id,
        name: data.name,
        fantasy: data.fantasy,
        mainContactName: data.mainContactName,
        mainContactDocNumber: data.mainContactDocNumber,
        mainContactPhone: data.mainContactPhone,
        docIdMainContactSelfieWithDoc,
        docIdMainContactDocPicture,
        docIdMainContactDocPictureBack,
        docIdCompanyConstitutionDoc,
      };

      const company: Models.Company =
        yield WebOnboardingHelpers.completeRetailer(dataToSave);
      // console.log('saved: ', company);
      yield put(appActions.setCompany(company));
      yield put(appActions.setOnboardingPhase('complete'));
      yield put(appActions.finishLoading());
      navigate('/');
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchCompleteSupplierOnboarding() {
  while (true) {
    const {
      payload: {
        data,
        navigate,
        selfieWithDocFile,
        docPictureFile,
        docPictureBackFile,
      },
    }: PayloadAction<{
      data: SupplierOnboardingForm;
      selfieWithDocFile: File;
      docPictureFile: File;
      docPictureBackFile?: File;
      navigate: NavigateFunction;
    }> = yield take(onboardingActions.completeSupplierOnboarding);
    yield put(appActions.startLoading());
    const { dbUser }: AppState = yield select((state) => state.app);

    try {
      if (!dbUser) throw new Error('Erro ao completar cadastro');

      let docIdSelfieWithDocFile = '';
      let docIdDocPictureFile = '';
      let docIdDocPictureBackFile = '';

      if (selfieWithDocFile) {
        docIdSelfieWithDocFile = yield WebDocumentHelpers.upload(
          selfieWithDocFile
        );
      }
      if (docPictureFile) {
        docIdDocPictureFile = yield WebDocumentHelpers.upload(docPictureFile);
      }
      if (docPictureBackFile) {
        docIdDocPictureBackFile = yield WebDocumentHelpers.upload(
          docPictureBackFile
        );
      }

      const dataToSave: Models.WebCompleteSupplierOnboardingRequest = {
        idCompany: dbUser.idCompany,
        name: data.name,
        docNumber: data.docNumber,
        phoneNumber: data.phoneNumber,
        idUser: dbUser.id,
        docIdSelfieWithDocFile,
        docIdDocPictureFile,
        docIdDocPictureBackFile,
      };

      const { company, user }: Models.WebCompleteSupplierOnboardingResponse =
        yield WebOnboardingHelpers.completeSupplier(dataToSave);
      // console.log('saved: ', company);
      yield put(appActions.setCompany(company));
      yield put(appActions.setDBUser(user));
      yield put(appActions.finishLoading());
      if (company.onboardingApproved) {
        yield put(appActions.setOnboardingPhase('approved'));
        window.location.href = '/';
      } else {
        yield put(appActions.setOnboardingPhase('complete'));
        navigate('/');
      }
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchResendVerificationEmail() {
  while (true) {
    const { payload: userId } = yield take(
      onboardingActions.resendVerificationEmail
    );
    try {
      yield put(appActions.startLoading());
      yield WebOnboardingHelpers.resendVerificationEmail(userId);
      yield put(onboardingActions.setEmailVerificationSent(true));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

export default [
  watchCompleteRetailerOnboarding,
  watchCompleteSupplierOnboarding,
  watchResendVerificationEmail,
];
