import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import BugReportIcon from '@mui/icons-material/BugReport';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShieldIcon from '@mui/icons-material/Shield';
import StoreIcon from '@mui/icons-material/Store';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import {
  Badge,
  Box,
  Divider,
  ListItemButton,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { default as React, forwardRef } from 'react';
import { NavLink, NavLinkProps, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { drawerWidth } from '../../helpers/themes';
import useCompanyStatus from '../../hooks/useCompanyStatus';
import useUserGrant from '../../hooks/useUserGrant';
import useUserRole from '../../hooks/useUserRole';
import { devToolsActions } from '../dev-tools/dev-tools-slice';
import { appActions } from './app-slice';

type DevButtonProps = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  active: boolean;
  ActiveIcon: React.ReactNode;
  InactiveIcon: React.ReactNode;
  activeText: string;
  inactiveText: string;
};
const DevButton = React.forwardRef<HTMLDivElement, DevButtonProps>(
  function DevButton(
    {
      onClick,
      active,
      ActiveIcon,
      InactiveIcon,
      activeText,
      inactiveText,
      ...other
    },
    ref
  ) {
    const { isDrawerOpen } = useAppSelector((state) => state.app);
    const muiTheme = useTheme();
    return (
      <ListItemButton
        ref={ref}
        {...other}
        onClick={onClick}
        sx={{
          borderLeft: '3px solid',
          borderColor: isDrawerOpen
            ? muiTheme.palette.background.default
            : muiTheme.palette.background.secondary,
          transition: muiTheme.transitions.create('all', {
            easing: muiTheme.transitions.easing.sharp,
            duration: muiTheme.transitions.duration.enteringScreen,
          }),
          ...(active && {
            // backgroundColor: 'black',
            '& *': {
              color: muiTheme.palette.error.main,
            },
          }),
        }}
      >
        <ListItemIcon>{active ? ActiveIcon : InactiveIcon}</ListItemIcon>
        <ListItemText primary={active ? activeText : inactiveText} />
      </ListItemButton>
    );
  }
);

export type RouterLinkProps = NavLinkProps & {
  children: React.ReactNode;
};
const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  function RouterLink({ children, ...other }: RouterLinkProps, ref) {
    const muiTheme = useTheme();
    const { isDrawerOpen } = useAppSelector((state) => state.app);
    return (
      <NavLink
        ref={ref}
        {...other}
        style={({ isActive }) => ({
          borderLeft: '3px solid',
          borderColor: isDrawerOpen
            ? muiTheme.palette.background.default
            : muiTheme.palette.background.secondary,
          transition: muiTheme.transitions.create('all', {
            easing: muiTheme.transitions.easing.sharp,
            duration: muiTheme.transitions.duration.enteringScreen,
          }),
          ...(isActive && {
            backgroundColor: muiTheme.palette.background.secondary,
            color: muiTheme.palette.getContrastText(
              muiTheme.palette.background.secondary
            ),
            borderLeft: '3px solid',
            borderColor: isDrawerOpen
              ? muiTheme.palette.background.secondary
              : muiTheme.palette.primary.main,
            '& *': {
              color: muiTheme.palette.getContrastText(
                muiTheme.palette.background.secondary
              ),
            },
            borderRadius: '0px 32px 32px 0px',
          }),
        })}
      >
        {({ isActive }) => (
          <Box
            component="span"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              ...(isActive && {
                '& *': {
                  color: muiTheme.palette.getContrastText(
                    muiTheme.palette.background.secondary
                  ),
                },
              }),
            }}
          >
            {children}
          </Box>
        )}
      </NavLink>
    );
  }
);

const DrawerNav = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 21,
  },
});

type AppDrawerProps = {
  isDrawerOpen: boolean;
};

export const AppDrawer = ({ isDrawerOpen }: AppDrawerProps) => {
  const dispatch = useAppDispatch();
  const { isLocalApi, skipRegistrar, isDebugActivated, isUserViewActivated } =
    useAppSelector((state) => state.devTools);
  const { operatingHours, company } = useAppSelector((state) => state.app);
  const muiTheme = useTheme();
  const { isDev: isDevOverride, isAdmin: isAdminOverride } = useUserRole(true);
  const { isDev, isAdmin } = useUserRole();
  const showPayments = useUserGrant([
    'supplier-customer-schedule-payments-view',
  ]);
  const showSettlements = useUserGrant([
    'supplier-customer-schedule-payments-view',
  ]);
  const showAccount = useUserGrant(['supplier-bankaccount']);
  const onDrawerClick = () => {
    dispatch(appActions.setIsDrawerOpen(!isDrawerOpen));
  };
  const navigate = useNavigate();
  const env =
    process.env.REACT_APP_ENV === 'paguerecebivel-dev'
      ? 'dev'
      : process.env.REACT_APP_ENV === 'paguerecebivel-stg'
      ? 'Demonstração'
      : 'prod';

  const theme = useTheme();
  const { bankAccountOk, targetBankAccountOk } = useCompanyStatus();
  const mainAccountOk = bankAccountOk && targetBankAccountOk;

  const logoAbrev =
    theme.palette.mode === 'dark'
      ? '/PR_LogoAbrev-FundoEscuro.png'
      : '/PR_LogoAbrev-FundoBranco.png';

  const logo =
    theme.palette.mode === 'dark'
      ? '/PR_LogoHorizontal-FundoEscuro.png'
      : '/PR_LogoHorizontal-FundoBranco.png';
  return (
    <Drawer
      variant={'permanent'}
      sx={{
        '& .MuiDrawer-paper': {
          position: 'relative',
          whiteSpace: 'nowrap',
          width: drawerWidth,
          transition: muiTheme.transitions.create('all', {
            easing: muiTheme.transitions.easing.sharp,
            duration: muiTheme.transitions.duration.enteringScreen,
          }),
          backgroundColor: muiTheme.palette.background.default,
          ...(!isDrawerOpen && {
            backgroundColor: muiTheme.palette.background.secondary,
            overflowX: 'hidden',
            transition: muiTheme.transitions.create('all', {
              easing: muiTheme.transitions.easing.sharp,
              duration: muiTheme.transitions.duration.leavingScreen,
            }),
            width: muiTheme.spacing(5),
            [muiTheme.breakpoints.up('sm')]: {
              width: muiTheme.spacing(7.75),
            },
          }),
          borderBottom: '0px',
          borderTop: '0px',
          borderLeft: '0px',
          borderRight: '0px',
          borderColor: muiTheme.palette.divider,
          overflowX: 'hidden',
        },
      }}
      open={isDrawerOpen}
    >
      <DrawerNav>
        <ListItemButton
          onClick={() => navigate('/')}
          sx={{
            borderLeft: '3px solid',
            borderColor: isDrawerOpen
              ? muiTheme.palette.background.default
              : muiTheme.palette.background.secondary,
            transition: muiTheme.transitions.create('all', {
              easing: muiTheme.transitions.easing.sharp,
              duration: muiTheme.transitions.duration.enteringScreen,
            }),
            justifyContent: 'space-between',
          }}
        >
          <Stack
            width={1}
            height="20px"
            alignItems="center"
            justifyContent="center"
          >
            {isDrawerOpen ? (
              <img src={logo} height="24px" />
            ) : (
              <img src={logoAbrev} height="16px" />
            )}
          </Stack>
        </ListItemButton>
        <ListItemButton
          onClick={onDrawerClick}
          sx={{
            borderLeft: '3px solid',
            borderColor: isDrawerOpen
              ? muiTheme.palette.background.default
              : muiTheme.palette.background.secondary,
            transition: muiTheme.transitions.create('all', {
              easing: muiTheme.transitions.easing.sharp,
              duration: muiTheme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <ListItemIcon>
            {isDrawerOpen ? (
              <ChevronLeftIcon fontSize="small" />
            ) : (
              <ChevronRightIcon fontSize="small" />
            )}
          </ListItemIcon>
        </ListItemButton>
        <Box padding={1}>
          <Divider />
        </Box>
        {company && company.companyType === 'supplier' && (
          <ListItemButton component={RouterLink} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}
        {company && company.companyType === 'supplier' && (
          <ListItemButton component={RouterLink} to="/clientes">
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItemButton>
        )}
        {company && company.companyType === 'supplier' && showPayments && (
          <ListItemButton component={RouterLink} to="/pagamentos">
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <ListItemText primary="Pagamentos" />
          </ListItemButton>
        )}
        {company && company.companyType === 'supplier' && showAccount && (
          <ListItemButton component={RouterLink} to="/conta-consolidadora">
            <ListItemIcon>
              <Badge
                invisible={mainAccountOk}
                overlap="rectangular"
                badgeContent="!"
                color="error"
              >
                <AccountBalanceWalletIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Conta Consolidadora" />
          </ListItemButton>
        )}
        {company && company.companyType === 'retailer' && (
          <ListItemButton component={RouterLink} to="/varejista">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        )}
        {company && company.companyType === 'retailer' && (
          <ListItemButton component={RouterLink} to="/agenda">
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText primary="Agenda" />
          </ListItemButton>
        )}
        {company &&
          company.companyType === 'supplier' &&
          showSettlements &&
          isAdmin && (
            <ListItemButton component={RouterLink} to="/liquidacoes">
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Liquidações" />
            </ListItemButton>
          )}
        {company && company.companyType === 'retailer' && (
          <ListItemButton component={RouterLink} to="/varejista-pagamentos">
            <ListItemIcon>
              <LocalAtmIcon />
            </ListItemIcon>
            <ListItemText primary="Pagamentos" />
          </ListItemButton>
        )}
        {isAdmin && (
          <Box padding={1}>
            <Divider />
          </Box>
        )}
        {isAdmin && (
          <ListItemButton component={RouterLink} to="/fornecedores">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Fornecedores" />
          </ListItemButton>
        )}
        <Box flexGrow={1}></Box>
      </DrawerNav>
      <Box flexGrow={1}></Box>

      <DrawerNav dense>
        {isDev && isDebugActivated && (
          <ListItemButton
            sx={{
              borderLeft: '3px solid',
              borderColor: isDrawerOpen
                ? muiTheme.palette.background.default
                : muiTheme.palette.background.secondary,
              '& *': {
                color: muiTheme.palette.error.main,
              },
            }}
            onClick={() =>
              setTimeout(() => {
                throw new Error('Simulação de erro');
              }, 1000)
            }
          >
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText primary="Simular Erro" />
          </ListItemButton>
        )}
        <ListItemButton component={RouterLink} to="/termos">
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Termos e Condições" />
        </ListItemButton>
        <ListItemButton component={RouterLink} to="/privacidade">
          <ListItemIcon>
            <ShieldIcon />
          </ListItemIcon>
          <ListItemText primary="Privacidade" />
        </ListItemButton>
        {isDev && (
          <>
            <DevButton
              onClick={() =>
                dispatch(
                  appActions.setOperatingHours({
                    isOperatingHours: !operatingHours?.isOperatingHours,
                    outsideOperatingHoursText:
                      operatingHours?.outsideOperatingHoursText ?? '',
                  })
                )
              }
              active={!operatingHours?.isOperatingHours}
              InactiveIcon={<WatchLaterIcon />}
              ActiveIcon={<AlarmOffIcon />}
              inactiveText="Horário OK"
              activeText="Fora do Horário"
            />

            {/* <Tooltip
              title={
                isLocalApi
                  ? `API LOCAL (${getBaseUrl('supplier')})`
                  : `API CLOUD (${getBaseUrl('supplier')})`
              }
            > */}
            <DevButton
              onClick={() =>
                dispatch(devToolsActions.setIsLocalApi(!isLocalApi))
              }
              active={isLocalApi}
              InactiveIcon={<CloudDoneIcon />}
              ActiveIcon={<CloudOffIcon />}
              inactiveText="API Nuvem"
              activeText="API Local"
            />
            {/* </Tooltip> */}
            <DevButton
              onClick={() =>
                dispatch(devToolsActions.setIsDebugActivated(!isDebugActivated))
              }
              active={isDebugActivated}
              InactiveIcon={<BuildCircleIcon />}
              ActiveIcon={<BuildCircleIcon />}
              inactiveText="Inspetor"
              activeText="Inspetor"
            />
            <DevButton
              onClick={() =>
                dispatch(devToolsActions.setSkipRegistrar(!skipRegistrar))
              }
              active={skipRegistrar}
              InactiveIcon={<LinkIcon />}
              ActiveIcon={<LinkOffIcon />}
              inactiveText="Registradora"
              activeText="Registradora"
            />
          </>
        )}
        {(isDevOverride || isAdminOverride) && (
          <DevButton
            onClick={() =>
              dispatch(
                devToolsActions.setIsUserViewActivated(!isUserViewActivated)
              )
            }
            active={isUserViewActivated}
            InactiveIcon={<SupervisedUserCircleIcon />}
            ActiveIcon={<SupervisedUserCircleIcon />}
            inactiveText="Visão Usuário"
            activeText="Visão Usuário"
          />
        )}
      </DrawerNav>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
        paddingBottom={1}
      >
        <Typography color="textSecondary" variant="caption" display="block">
          {process.env.REACT_APP_VERSION} {env}
        </Typography>
      </Stack>
    </Drawer>
  );
};
