import { useAppSelector } from '../helpers/hooks';

const useUserRole = (ignoreUserView?: boolean) => {
  const userRole = useAppSelector((state) => state.app.dbUser?.userRole);
  const isUserViewActivated = useAppSelector(
    (state) => state.devTools.isUserViewActivated
  );
  const isAdmin =
    isUserViewActivated && !ignoreUserView
      ? false
      : ['dev', 'adm'].includes(userRole ?? '');
  const isDev =
    isUserViewActivated && !ignoreUserView ? false : userRole === 'dev';
  return { isAdmin, isDev };
};
export default useUserRole;
