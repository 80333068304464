import Dialog, { DialogProps } from '@mui/material/Dialog';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';

type ResponsiveDialogProps = {
  fullHeight?: boolean;
} & DialogProps;

export default function ResponsiveDialog({
  children,
  ...otherProps
}: ResponsiveDialogProps) {
  const fullScreen = useIsSmallScreen();

  return (
    <Dialog fullScreen={fullScreen} {...otherProps}>
      {children}
    </Dialog>
  );
}
