import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Models } from 'types';
import { useAppSelector } from './helpers/hooks';
import useUserRole from './hooks/useUserRole';

type AuthenticationGuardProps<T> = {
  component: React.ComponentType<T>;
  componentProps?: T;
  requiredRoles?: Models.UserRole[];
};
export const AuthenticationGuard = <T extends object>({
  component,
  componentProps,
  requiredRoles,
}: AuthenticationGuardProps<T>) => {
  const location = useLocation();
  const Component: React.FC<T> = withAuthenticationRequired<T>(component, {
    onRedirecting: () => <></>,
  });
  const onboardingPhase = useAppSelector((state) => state.app.onboardingPhase);
  const dbUser = useAppSelector((state) => state.app.dbUser);
  const { isAdmin } = useUserRole();
  // const isLoading = useAppSelector((state) => state.app.isLoading);
  // if (isLoading) return <PageLoader />;

  // if (onboardingPhase === 'not authenticated') return <></>;

  let redirect = '/';
  switch (onboardingPhase) {
    case 'unexpected':
      redirect = '/unexpected';
      break;
    case 'no invite':
      redirect = '/no-invite';
      break;
    case 'wrong email':
      redirect = '/wrong-email';
      break;
    case 'incomplete':
      redirect = '/onboarding';
      break;
    case 'unknown user':
      redirect = '/unknown-user';
      break;
    case 'complete':
      redirect = '/pending';
      break;
    case 'approved':
      redirect = '/';
  }
  const allow =
    onboardingPhase === 'approved' ||
    onboardingPhase === 'not authenticated' ||
    location.pathname === redirect; // Para evitar Navigate circular

  // console.log('location.pathname: ', location.pathname);
  if (onboardingPhase === 'approved' && location.pathname === '/pending')
    return <Navigate to="/" replace />;
  if (
    onboardingPhase === 'approved' &&
    location.pathname === '/email-not-verified'
  )
    return <Navigate to="/" replace />;
  if (onboardingPhase === 'approved' && location.pathname === '/unexpected')
    return <Navigate to="/" replace />;

  if (
    allow &&
    dbUser &&
    requiredRoles &&
    !requiredRoles.includes(dbUser.userRole)
  )
    return <Navigate to="/not-found" replace />;

  return isAdmin || allow ? (
    <Component {...(componentProps ?? ({} as unknown as T))} />
  ) : (
    <Navigate to={redirect} replace />
  );
};
