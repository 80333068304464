import dddJson from './ddd';

const regex =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm;

export const testEmail = (strEmail?: string) => {
  if (!strEmail) return false;
  let m;
  let match = false;

  // eslint-disable-next-line no-unused-vars
  while ((m = regex.exec(strEmail)) !== null) {
    match = true;
  }

  return match;
};

export const testPhone = (strPhone: string) => {
  const strippedPhone = strPhone.replace(/[^\d+]+/g, '');
  if (strippedPhone.substring(0, 2) === '+1')
    return strippedPhone.length === 12;
  if (strippedPhone.substring(0, 1) === '+') return true;
  if (strippedPhone.length < 10 || strippedPhone.length > 11) return false;
  const [ddd] = [strippedPhone.substring(0, 2), strippedPhone.substring(2)];
  if (!Object.keys(dddJson.estadoPorDdd).find((x) => x === ddd)) return false;
  return true;
};

export const testCPF = (strCPF: string): boolean => {
  const strippedCPF = strCPF.replace(/[^\d+]+/g, '');
  var Soma;
  var Resto;
  Soma = 0;
  if (strippedCPF === undefined) return false;
  if (strippedCPF == '00000000000') return false;
  if (strippedCPF == '11111111111') return false;
  if (strippedCPF == '22222222222') return false;
  if (strippedCPF == '33333333333') return false;
  if (strippedCPF == '44444444444') return false;
  if (strippedCPF == '55555555555') return false;
  if (strippedCPF == '66666666666') return false;
  if (strippedCPF == '77777777777') return false;
  if (strippedCPF == '88888888888') return false;
  if (strippedCPF == '99999999999') return false;
  if (strippedCPF.length !== 11) return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strippedCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strippedCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strippedCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strippedCPF.substring(10, 11))) return false;
  return true;
};

export const testCNPJ = (cnpj: string): boolean => {
  if (cnpj === undefined) return false;
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != parseInt(digitos.charAt(0))) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != parseInt(digitos.charAt(1))) return false;

  return true;
};

// const strongPassword = new RegExp(
//   '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
// );
// const mediumPassword = new RegExp(
//   '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'
// );
const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
const mediumPassword = new RegExp(
  '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.{8,}))'
);
const minimumLengthExp = new RegExp(/.{8,}/);
const oneUppercase = new RegExp(/.*[A-Z]/);
const oneLowercase = new RegExp(/.*[a-z]/);
const oneDigit = new RegExp(/.*[0-9]/);
// const oneSpecialCharacter = new RegExp(/[^A-Za-z0-9]/);

type PasswordStrengthCondition = {
  label: string;
  valid: boolean;
};
type PasswordStrengthStatus = 'empty' | 'strong' | 'medium' | 'weak';
export type PasswordStrength = {
  strength: PasswordStrengthStatus;
  conditions: PasswordStrengthCondition[];
};
export const testPassword = (password: string): PasswordStrength => {
  let strength: PasswordStrengthStatus;

  const conditions = [
    {
      label: '8 caracteres ou mais',
      valid: minimumLengthExp.test(password),
    },
    {
      label: '1 letra maiúscula',
      valid: oneUppercase.test(password),
    },
    {
      label: '1 letra minúscula',
      valid: oneLowercase.test(password),
    },
    {
      label: '1 número',
      valid: oneDigit.test(password),
    },
    // {
    //   label: '1 caractere especial',
    //   valid: oneSpecialCharacter.test(password),
    // },
  ];
  if (password.trim() === '') {
    strength = 'empty';
  } else if (strongPassword.test(password)) {
    strength = 'strong';
  } else if (mediumPassword.test(password)) {
    strength = 'medium';
  } else {
    strength = 'weak';
  }

  return {
    strength,
    conditions,
  };
};

export const getNumbersRegex = () => /^[0-9]*$/;
export const getDecimalsRegex = () => /^[0-9.,]*$/;
export const getValidLettersRegex = () =>
  /^[a-zA-ZáéíóúàèìòùãõäëïöüçÁÉÍÓÚÀÈÌÒÙÃÕÄËÏÖÜÇ ]*$/;
export const getValidAddressRegex = () =>
  /^[0-9a-zA-ZáéíóúàèìòùãõäëïöüçÁÉÍÓÚÀÈÌÒÙÃÕÄËÏÖÜÇ,/\-(). ]*$/;
export const getValidCompanyRegex = () =>
  /^[0-9a-zA-ZáéíóúàèìòùãõäëïöüçÁÉÍÓÚÀÈÌÒÙÃÕÄËÏÖÜÇ,/\-(). &]*$/;
export const getValidDocumentRegex = () => /^[0-9/\-().]*$/;

export const validateFloat = (v: string) => {
  const val = parseFloat((v ?? '').replace(',', '.'));
  return !isNaN(val);
};

export type MimeType =
  | 'image/jpeg'
  | 'image/png'
  | 'application/pdf'
  | 'application/zip';

export const validateFileSize = (file: File, maxBytes: number) => {
  const { size } = file;
  return size <= maxBytes;
};

export const validateFileType = (file: File, acceptedTypes: MimeType[]) => {
  const { type } = file;
  return acceptedTypes.includes(type as MimeType);
};
