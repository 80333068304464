import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Validate, useController } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { cleanPhone } from '../../helpers/clean-phone';
import { testPhone } from '../../helpers/validations';

type CustomProps = {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

type PhoneType = 'unknown' | 'br-no-prefix' | 'br-prefix' | 'us' | 'intl';
type Mask = {
  mask: string;
  definitions: Record<string, any>;
  type: PhoneType;
  lazy: boolean;
};

const masks: Mask[] = [
  {
    mask: '#',
    definitions: {
      '#': /(\+|[1-9])/ as any,
    },
    type: 'unknown',
    lazy: true,
  },
  {
    mask: '(##) 0000-0000',
    definitions: {
      '#': /[1-9]/ as any,
    },
    type: 'br-no-prefix',
    lazy: true,
  },
  {
    mask: '(##) 90000-0000',
    definitions: {
      '#': /[1-9]/ as any,
    },
    type: 'br-prefix',
    lazy: true,
  },
  {
    mask: '+1 (000) 000-0000',
    definitions: {
      '#': /[1-9]/ as any,
    },
    type: 'us',
    lazy: true,
  },
  {
    mask: '+0000000000000',
    definitions: {
      '#': /[1-9]/ as any,
    },
    type: 'intl',
    lazy: true,
  },
];

const detectPhoneType = (rawNumber: string): PhoneType => {
  var number = rawNumber.replace(/\D/g, '');
  if (!rawNumber || rawNumber.length === 0) {
    return 'unknown';
  } else if (rawNumber.slice(0, 1) === '+') {
    return rawNumber.slice(0, 2) === '+1' ? 'us' : 'intl';
  } else {
    return number.slice(2, 3) === '9' ? 'br-prefix' : 'br-no-prefix';
  }
};

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={masks}
        dispatch={(appended, dynamicMasked) => {
          const type = detectPhoneType(dynamicMasked.value + appended);
          return (
            dynamicMasked.compiledMasks.find(
              (mask) => (mask as any).type === type
            ) ?? dynamicMasked.compiledMasks[0]
          );
        }}
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
      />
    );
  }
);

type PhoneFieldProps = TextFieldProps & {
  control: Control<any, any>;
  customValidate?: Validate<any, any>;
};

export const PhoneField = (props: PhoneFieldProps) => {
  const {
    control,
    customValidate,
    InputProps,
    onChange: onTextFieldChange,
    ...other
  } = props;
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name: other.name ?? '',
    control,
    rules: {
      validate:
        customValidate ??
        ((value) => {
          if (!value) return 'Telefone obrigatório';
          if (!testPhone(value)) return 'Telefone inválido';
          return true;
        }),
    },
    defaultValue: '',
  });
  return (
    <TextField
      {...other}
      value={value}
      error={!!error}
      helperText={error?.message}
      onChange={(e) => {
        if (onTextFieldChange) onTextFieldChange(e);
        onChange(cleanPhone(e.target.value));
      }}
      InputProps={{ ...InputProps, inputComponent: TextMaskCustom as any }}
    />
  );
};
