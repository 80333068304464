import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Constants } from 'pr-constants';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../../components/EditForm/EditForm';
import EditFormAction from '../../../components/EditForm/EditFormAction';
import EditFormContent from '../../../components/EditForm/EditFormContent';
import EditFormHeader from '../../../components/EditForm/EditFormHeader';
import { SelectFieldWithControl } from '../../../components/SelectField/SelectFieldWithControl';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import useUserRole from '../../../hooks/useUserRole';
import FirebaseIcon from '../../../icons/FirebaseIcon';
import { SaveSupplierUserForm } from '../../../types';
import { settingsActions } from '../settings-slice';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

type DocumentDetailProps = {
  label: string;
  missing: boolean;
  isDownloading: boolean;
  onDownloadClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
};

const DocumentDetail = ({
  label,
  missing,
  isDownloading,
  onDownloadClick,
  children,
}: DocumentDetailProps) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
      <LoadingButton
        loading={isDownloading}
        disabled={missing}
        loadingPosition="start"
        startIcon={<DownloadIcon />}
        onClick={onDownloadClick}
      >
        {label}
      </LoadingButton>
      <Box flexGrow={1}></Box>
      {children}
    </Box>
  );
};

export default function EditUser() {
  const dispatch = useAppDispatch();
  const { company } = useAppSelector((state) => state.app);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const { isAdmin } = useUserRole();
  const {
    supplierUserGroups,
    selectedSupplierUser,
    userEditFormMode,
    isSavingOrDeletingSupplierUsers,
    loadingButtons,
  } = useAppSelector((state) => state.settings);

  const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] =
    useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const defaultValues: SaveSupplierUserForm = {
    email: selectedSupplierUser?.email ?? '',
    id: selectedSupplierUser?.id,
    mode: userEditFormMode,
    idSupplierUserGroup:
      selectedSupplierUser?.idSupplierUserGroup ?? Constants.adminGroup.id,
  };

  const {
    handleSubmit,
    formState: { isDirty, errors },
    // watch,
    register,
    control,
    // setValue,
  } = useForm<SaveSupplierUserForm>({
    defaultValues,
  });

  const handleCloseClick = () => {
    if (isDirty) setIsDiscardConfirmationOpen(true);
    else closeForm();
  };

  const handleDeleteClick = () => setIsDeleteConfirmationOpen(true);
  const handleDeleteConfirm = () => {
    if (selectedSupplierUser)
      dispatch(settingsActions.deleteSupplierUser(selectedSupplierUser.id));
  };

  const closeForm = () => {
    dispatch(settingsActions.setIsUserEditFormOpen(false));
  };

  const onSubmit = (data: SaveSupplierUserForm) => {
    dispatch(settingsActions.saveSupplierUser(data));
  };

  const getDocumentLink = (id?: string, buttonId?: string) => {
    if (id && buttonId)
      dispatch(settingsActions.getDownloadLink({ id, buttonId }));
  };

  const groupOptions = supplierUserGroups.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return (
    <EditForm>
      <EditFormHeader
        title={userEditFormMode === 'edit' ? 'Alterar' : 'Novo Usuário'}
        onCloseClick={handleCloseClick}
        actions={
          isDebugActivated &&
          selectedSupplierUser?.id && (
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href={getFirestoreConsoleLink(
                `/users/${selectedSupplierUser?.id}`
              )}
            >
              <FirebaseIcon />
            </IconButton>
          )
        }
      />

      <EditFormContent display="flex" flexDirection="column" gap={2}>
        <Grid2 container spacing={2}>
          <Grid2 xs={isAdmin ? 6 : 12}>
            <TextField
              fullWidth
              id="email"
              label="E-mail"
              disabled={userEditFormMode === 'edit'}
              type="text"
              {...register('email', {
                required: 'O e-mail é obrigatório',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"À-ÿ]+(\.[^<>()[\]\\.,;:\s@"À-ÿ]+)*)|("[a-zA-Z\-0-9]+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/,
                  message: 'E-mail inválido',
                },
              })}
              error={!!errors?.email}
              helperText={errors?.email?.message}
            />
          </Grid2>
          <Grid2 xs={isAdmin ? 6 : 12}>
            <SelectFieldWithControl
              key="idSupplierUserGroup"
              name="idSupplierUserGroup"
              label="Grupo"
              disabled={
                company?.idOwner !== undefined &&
                company?.idOwner === selectedSupplierUser?.id
              }
              control={control}
              options={groupOptions}
            />
          </Grid2>
          {userEditFormMode === 'edit' && (
            <>
              <Grid2 xs={12}>
                <Typography variant="body2" color="text.secondary">
                  Dados preenchidos pelo usuário
                </Typography>
              </Grid2>
              <Grid2 xs={4}>
                <Detail label="Nome" value={selectedSupplierUser?.name} />
              </Grid2>
              <Grid2 xs={4}>
                <Detail
                  label="Documento"
                  value={format(selectedSupplierUser?.docNumber, 'docNumber')}
                />
              </Grid2>
              <Grid2 xs={4}>
                <Detail
                  label="Nome"
                  value={format(selectedSupplierUser?.phoneNumber, 'phone')}
                />
              </Grid2>
            </>
          )}

          {isAdmin && (
            <>
              <Grid2 xs={12}>
                <Typography variant="body2" color="text.secondary">
                  Documentos
                </Typography>
              </Grid2>
              <Grid2 xs={4}>
                <DocumentDetail
                  label="Selfie"
                  missing={
                    (selectedSupplierUser?.docIdSelfieWithDocFile ?? '') === ''
                  }
                  isDownloading={loadingButtons['selfieWithDocFile']}
                  onDownloadClick={() =>
                    getDocumentLink(
                      selectedSupplierUser?.docIdSelfieWithDocFile,
                      'selfieWithDocFile'
                    )
                  }
                />
              </Grid2>
              <Grid2 xs={4}>
                <DocumentDetail
                  label="Documento"
                  missing={
                    (selectedSupplierUser?.docIdDocPictureFile ?? '') === ''
                  }
                  isDownloading={loadingButtons['docPictureFile']}
                  onDownloadClick={() =>
                    getDocumentLink(
                      selectedSupplierUser?.docIdDocPictureFile,
                      'docPictureFile'
                    )
                  }
                />
              </Grid2>
              {(selectedSupplierUser?.docIdDocPictureBackFile ?? '') !== '' && (
                <Grid2 xs={4}>
                  <DocumentDetail
                    label="Doc. Verso"
                    missing={false}
                    isDownloading={loadingButtons['docPictureBackFile']}
                    onDownloadClick={() =>
                      getDocumentLink(
                        selectedSupplierUser?.docIdDocPictureBackFile,
                        'docPictureBackFile'
                      )
                    }
                  />
                </Grid2>
              )}
              <Grid2 xs={12}>
                <Divider />
              </Grid2>
            </>
          )}
        </Grid2>
      </EditFormContent>
      <EditFormAction>
        <Stack direction="row" justifyContent="flex-end" paddingTop={2} gap={1}>
          {userEditFormMode === 'edit' && (
            <LoadingButton
              onClick={handleDeleteClick}
              variant="contained"
              color="error"
              disabled={company?.idOwner === selectedSupplierUser?.id}
              loading={isSavingOrDeletingSupplierUsers}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
            >
              Excluir
            </LoadingButton>
          )}
          <Button onClick={handleCloseClick} variant="outlined">
            Cancelar
          </Button>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
            type="submit"
            loading={isSavingOrDeletingSupplierUsers}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </Stack>
      </EditFormAction>
      <ConfirmationDialog
        open={isDiscardConfirmationOpen}
        title="Existem dados não salvos"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsDiscardConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Sair sem Salvar',
            buttonProps: {
              onClick: () => {
                closeForm();
              },
            },
          },
          {
            name: 'Salvar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              startIcon: <SaveIcon />,
              onClick: () => {
                setIsDiscardConfirmationOpen(false);
                handleSubmit(onSubmit)();
              },
            },
          },
        ]}
      >
        <Typography>
          Alguns dados foram modificados e ainda não foram salvos.
        </Typography>
        <Typography>Deseja salvá-los antes de fechar a janela?</Typography>
      </ConfirmationDialog>

      <ConfirmationDialog
        open={isDeleteConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsDeleteConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Excluir',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'error',
              startIcon: <DeleteIcon />,
              onClick: () => {
                setIsDeleteConfirmationOpen(false);
                handleDeleteConfirm();
              },
            },
          },
        ]}
      >
        <Alert variant="outlined" severity="error">
          Atenção! Deseja excluir este usuário?
        </Alert>
      </ConfirmationDialog>
    </EditForm>
  );
}
