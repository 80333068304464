/* eslint-disable no-unused-vars */
import { ThemeOptions, createTheme, responsiveFontSizes } from '@mui/material';
import { ptBR } from '@mui/material/locale';
import { ptBR as ptBRX } from '@mui/x-data-grid/locales';
import _ from 'lodash';

declare module '@mui/material' {
  interface Theme {
    table: {
      border: string;
    };
  }
  interface TypeBackground {
    field: string;
    secondary: string;
  }
  interface ThemeOptions {
    table: {
      border: string;
    };
  }
}

const primaryLight =
  process.env.REACT_APP_ENV === 'paguerecebivel-dev'
    ? { main: '#116ADC' }
    : process.env.REACT_APP_ENV === 'paguerecebivel-stg'
    ? { main: '#116ADC' }
    : { main: '#116ADC' };

const secondaryLight =
  process.env.REACT_APP_ENV === 'paguerecebivel-dev'
    ? { main: '#000000' }
    : process.env.REACT_APP_ENV === 'paguerecebivel-stg'
    ? { main: '#000000' }
    : { main: '#000000' };

const primaryDark =
  process.env.REACT_APP_ENV === 'paguerecebivel-dev'
    ? { main: '#116ADC' }
    : process.env.REACT_APP_ENV === 'paguerecebivel-stg'
    ? { main: '#116ADC' }
    : { main: '#116ADC' };

const secondaryDark =
  process.env.REACT_APP_ENV === 'paguerecebivel-dev'
    ? { main: '#FFFFFF' }
    : process.env.REACT_APP_ENV === 'paguerecebivel-stg'
    ? { main: '#FFFFFF' }
    : { main: '#FFFFFF' };

export const drawerWidth = '220px';

const baseTheme: ThemeOptions = {
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       // html: {
  //       //   WebkitFontSmoothing: undefined,
  //       //   MozOsxFontSmoothing: undefined,
  //       // },
  //       body: {
  //         margin: 0,
  //         padding: 0,
  //       },
  //     },
  //   },
  // },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
    fontSize: 13,
    h4: {
      fontSize: 28,
    },
  },
  palette: {
    // primary: {
    //   main: '#3E4041',
    // },
    // secondary: {
    //   main: '#116ADC',
    // },
    success: {
      main: '#669d33',
    },

    // error: {
    //   main: '#e53c58',
    // },
    // warning: {
    //   main: '#f7ac6f',
    // },
    // text: {
    //   primary: 'rgba(102, 45, 145, 0.87)',
    //   secondary: 'rgba(102, 45, 145, 0.6)',
    //   disabled: 'rgba(102, 45, 145, 0.38)',
    // },
    // divider: 'rgba(102, 45, 145, 0.12)',
  },
  // shape: {
  //   borderRadius: 32,
  // },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        outlined: {
          fontWeight: 700,
        },
        root: {
          borderRadius: 999,
          boxShadow: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       backgroundColor: 'rgba(166, 138, 189, 0.92)',
    //     },
    //   },
    // },
  },
  table: {
    border: '#e0e0e0',
  },
};

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: primaryLight,
    secondary: secondaryLight,
    background: {
      default: '#f4f5f7',
      paper: '#ffffff',
      field: '#f4f5f7',
      secondary: '#ffffff',
    },
    divider: 'rgba(0,0,0,0.3)',
    action: {
      hover: 'rgba(0,0,0,0.10)',
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       outlined: {
  //         borderColor: primaryLight.main,
  //       },
  //     },
  //   },
  // },
  table: {
    border: '#e0e0e0',
  },
};

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: primaryDark,
    secondary: secondaryDark,
    background: {
      default: '#303030',
      paper: '#000000',
      field: '#303030',
      secondary: '#000000',
    },
    divider: 'rgba(255,255,255,0.3)',
    action: {
      hover: 'rgba(255,255,255,0.15)',
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       outlined: {
  //         borderColor: primaryDark.main,
  //       },
  //     },
  //   },
  // },
  table: {
    border: '#515151',
  },
};

export const lightTheme = responsiveFontSizes(
  createTheme(_.merge(baseTheme, lightThemeOptions), ptBR, ptBRX)
);
export const darkTheme = responsiveFontSizes(
  createTheme(_.merge(baseTheme, darkThemeOptions), ptBR, ptBRX)
);
