import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import TuneIcon from '@mui/icons-material/Tune';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Alert, LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import ResponsiveDialog from '../../components/ResponsiveDialog/ResponsiveDialog';
import { getFirestoreConsoleLink } from '../../helpers/firebase';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { safeParseFloat } from '../../helpers/safe-parse';
import { validateFloat } from '../../helpers/validations';
import useCompanyStatus from '../../hooks/useCompanyStatus';
import useUserRole from '../../hooks/useUserRole';
import FirebaseIcon from '../../icons/FirebaseIcon';
import { appActions } from '../app/app-slice';
import { bankAccountActions } from './bank-account-slice';
import PixLimits from './PixLimits';
import TargetBankAccountForm from './TargetBankAccountForm';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

export default function SupplierBankAccount() {
  const dispatch = useAppDispatch();
  const { isLoading, bankAccount, targetBankAccount, company } = useAppSelector(
    (state) => state.app
  );
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const {
    bankAccountInfo,
    isSavingTargetAccount,
    isTargetAccountInEditMode,
    isTransferring,
    isTransferDialogOpen,
    isTransferConfirmationOpen,
    isInternalTransferDialogOpen,
    // isInternalTransferConfirmationOpen,
    isPixLimitsFormOpen,
    isLoadingBankAccountInfo,
    isLoadingPixLimitsUsage,
  } = useAppSelector((state) => state.bankAccount);

  const { targetBankAccountOk } = useCompanyStatus();

  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const { isAdmin } = useUserRole();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [transferType, setTransferType] = useState<'pix' | 'ted'>('pix');
  const [transferAmount, setTransferAmount] = useState('');
  const [transferDescription, setTransferDescription] = useState('');

  const isBankAccountReady = bankAccount?.bankStatus === 'account_opened';

  useEffect(() => {
    if (bankAccount && isBankAccountReady) {
      dispatch(bankAccountActions.getSupplierAccountInfo());
    }
  }, [bankAccount]);

  const handleCloseClick = () => {
    dispatch(appActions.setIsBankAccountPanelOpen(false));
  };
  const handleCreateClick = () => {
    setIsConfirmationOpen(true);
  };
  const handleConfirmClick = () => {
    dispatch(bankAccountActions.createAccount({}));
    setIsConfirmationOpen(false);
  };

  const handleEditTargetBankAccountClick = () => {
    dispatch(bankAccountActions.setSearchPixKeyResponse(undefined));
    dispatch(bankAccountActions.setIsTargetAccountInEditMode(true));
  };

  const handleCloseEditTargetBankAccountClick = () => {
    dispatch(bankAccountActions.setIsTargetAccountInEditMode(false));
  };

  const handleOpenTransferDialogClick = () => {
    dispatch(bankAccountActions.setIsTransferDialogOpen(true));
  };

  const handleOpenPixLimitsDialogClick = () => {
    dispatch(bankAccountActions.setIsPixLimitsFormOpen(true));
  };

  const handleTransferConfirmClick = () => {
    dispatch(
      bankAccountActions.transferToTargetAccount({
        transferType,
        transferAmount: safeParseFloat(transferAmount),
      })
    );
  };

  // const handleInternalTransferConfirmClick = () => {
  //   dispatch(
  //     bankAccountActions.internalTransferToTargetAccount({
  //       transferAmount: safeParseFloat(transferAmount),
  //       description: transferDescription,
  //     })
  //   );
  // };

  const bankAccountNameWithCode = bankAccount
    ? bankAccount.bankCode
      ? `${bankAccount.bankCode} - ${bankAccount.bankName}`
      : bankAccount.bankName
    : '';

  const targetBankAccountNameWithCode = targetBankAccount
    ? targetBankAccount.bankCode
      ? `${targetBankAccount.bankCode} - ${targetBankAccount.bankName}`
      : targetBankAccount.bankName
    : '';
  const accountData = `Agência ${bankAccount?.branch} - Número ${bankAccount?.account}-${bankAccount?.accountDigit}`;
  const targetAccountData = `Agência ${targetBankAccount?.branch} - Número ${targetBankAccount?.account}-${targetBankAccount?.accountDigit}`;

  const balanceAfterTransfer =
    (bankAccountInfo?.balance ?? 0) - safeParseFloat(transferAmount);

  const isBalanceValid =
    validateFloat(transferAmount) &&
    balanceAfterTransfer >= 0 &&
    safeParseFloat(transferAmount) > 0;

  const isTransferDescriptionValid = transferDescription.trim() !== '';

  const accountType =
    company?.companyType === 'supplier'
      ? 'Conta Consolidadora'
      : 'Conta de Liquidação';

  return (
    <EditForm>
      <EditFormHeader title={accountType} onCloseClick={handleCloseClick} />
      <EditFormContent display="flex" flexDirection="row" gap={2}>
        <Stack
          direction="column"
          gap={1}
          flexBasis={
            company?.companyType === 'supplier' &&
            bankAccount &&
            isBankAccountReady
              ? '50%'
              : '100%'
          }
        >
          {bankAccount && isBankAccountReady ? (
            <>
              <Stack direction="row" alignItems="center">
                <Box flexGrow={1}>
                  <Typography>Dados da {accountType}</Typography>
                </Box>
                {isDebugActivated && (
                  <IconButton
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getFirestoreConsoleLink(
                      `/bank-accounts/${bankAccount?.id}`
                    )}
                  >
                    <FirebaseIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => setIsBalanceVisible(!isBalanceVisible)}
                >
                  {isBalanceVisible ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
                <IconButton
                  onClick={() =>
                    dispatch(bankAccountActions.getSupplierAccountInfo())
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </Stack>
              <Stack direction="row" gap={2}>
                <Detail
                  label="Saldo"
                  value={
                    isLoadingBankAccountInfo
                      ? ''
                      : isBalanceVisible
                      ? format(bankAccountInfo?.balance, 'money')
                      : '***'
                  }
                >
                  {isLoadingBankAccountInfo && <CircularProgress size={15} />}
                </Detail>
                {!isLoadingBankAccountInfo &&
                  company?.companyType === 'retailer' &&
                  isBalanceVisible && (
                    <IconButton
                      onClick={() =>
                        dispatch(
                          bankAccountActions.setIsInternalTransferDialogOpen(
                            true
                          )
                        )
                      }
                    >
                      <SendIcon />
                    </IconButton>
                  )}
              </Stack>
              <Detail
                label="Saldo Bloqueado"
                value={
                  isLoadingBankAccountInfo
                    ? ''
                    : isBalanceVisible
                    ? format(bankAccountInfo?.blocked_balance, 'money')
                    : '***'
                }
              >
                {isLoadingBankAccountInfo && <CircularProgress size={15} />}
              </Detail>

              <Detail label="Banco" value={bankAccountNameWithCode} />
              <Detail label="ISPB" value={format(bankAccount.ispb, 'ispb')} />
              <Detail label="Dados" value={accountData} />
              <Detail
                label="Documento"
                value={format(bankAccount.documentNumber, 'docNumber')}
              />
              <Detail label="Nome" value={bankAccount.ownerName} />
              {isDebugActivated && (
                <>
                  <Detail label="ID" value={bankAccount.bankId} />
                  <Detail label="Status" value={bankAccount.bankStatus} />
                </>
              )}
              <Detail
                label="Data de criação"
                value={format(bankAccount.dateadded, 'dateTime')}
              />
            </>
          ) : (
            <>
              {isAdmin && !bankAccount && (
                <LoadingButton
                  loading={isLoading}
                  loadingPosition="start"
                  startIcon={<AddIcon />}
                  variant="outlined"
                  onClick={handleCreateClick}
                >
                  Criar nova conta
                </LoadingButton>
              )}
              {bankAccount && !isBankAccountReady && (
                <>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box flexGrow={1}>
                      <Alert variant="outlined" severity="info">
                        Conta em análise
                      </Alert>
                    </Box>
                    {isDebugActivated && (
                      <IconButton
                        target="_blank"
                        rel="noopener noreferrer"
                        href={getFirestoreConsoleLink(
                          `/bank-accounts/${bankAccount?.id}`
                        )}
                      >
                        <FirebaseIcon />
                      </IconButton>
                    )}
                    <IconButton
                      disabled={isLoadingBankAccountInfo}
                      onClick={() =>
                        dispatch(bankAccountActions.getSupplierAccountInfo())
                      }
                    >
                      {isLoadingBankAccountInfo ? (
                        <CircularProgress size="1em" />
                      ) : (
                        <RefreshIcon />
                      )}
                    </IconButton>
                  </Stack>
                  <Detail label="Banco" value={bankAccountNameWithCode} />
                  <Detail
                    label="ISPB"
                    value={format(bankAccount.ispb, 'ispb')}
                  />
                  <Detail label="Dados" value={accountData} />
                </>
              )}
            </>
          )}
        </Stack>
        {bankAccount &&
          isBankAccountReady &&
          targetBankAccount &&
          !isTargetAccountInEditMode && (
            <Stack direction="column" gap={2} flexBasis="50%">
              <Stack direction="row" alignItems="center">
                <Box flexGrow={1}>
                  <Typography>Dados da Conta Destino</Typography>
                </Box>
                {isDebugActivated && (
                  <IconButton
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getFirestoreConsoleLink(
                      `/target-bank-accounts/${targetBankAccount?.id}`
                    )}
                  >
                    <FirebaseIcon />
                  </IconButton>
                )}
                {isAdmin && (
                  <IconButton onClick={handleEditTargetBankAccountClick}>
                    <EditIcon />
                  </IconButton>
                )}
              </Stack>
              <Detail label="Banco" value={targetBankAccountNameWithCode} />
              <Detail
                label="ISPB"
                value={format(targetBankAccount.ispb, 'ispb')}
              />
              <Detail label="Dados" value={targetAccountData} />
              <Detail
                label="Documento"
                value={format(targetBankAccount.documentNumber, 'docNumber')}
              />
              <Detail
                label="Data de criação"
                value={format(targetBankAccount.createdAt, 'dateTime')}
              />
              <Detail label="Chave PIX" value={targetBankAccount.pixKey} />
              {isAdmin && (
                <LoadingButton
                  loadingPosition="start"
                  startIcon={<SendIcon />}
                  loading={isSavingTargetAccount || isLoadingBankAccountInfo}
                  variant="outlined"
                  onClick={handleOpenTransferDialogClick}
                >
                  Transferir
                </LoadingButton>
              )}
              <LoadingButton
                startIcon={<TuneIcon />}
                variant="outlined"
                loading={isLoadingPixLimitsUsage}
                loadingPosition="start"
                onClick={handleOpenPixLimitsDialogClick}
              >
                Limites PIX
              </LoadingButton>
            </Stack>
          )}
        {company?.companyType === 'supplier' &&
          bankAccount &&
          isBankAccountReady &&
          (!targetBankAccount ||
            (targetBankAccount && isTargetAccountInEditMode)) && (
            <Stack
              direction="column"
              gap={2}
              flexBasis="50%"
              flexGrow={1}
              height={1}
            >
              <Stack direction="column" gap={2}>
                {!targetBankAccountOk && (
                  <Alert variant="outlined" severity="error">
                    É necessário configurar a conta destino!
                  </Alert>
                )}
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <Typography>
                      {targetBankAccount
                        ? 'Dados da Conta Destino'
                        : 'Informar dados da Conta Destino'}
                    </Typography>
                  </Box>
                  {isDebugActivated && (
                    <IconButton
                      target="_blank"
                      rel="noopener noreferrer"
                      href={getFirestoreConsoleLink(
                        `/target-bank-accounts/${targetBankAccount?.id}`
                      )}
                    >
                      <FirebaseIcon />
                    </IconButton>
                  )}
                  {targetBankAccount && (
                    <IconButton onClick={handleCloseEditTargetBankAccountClick}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </Stack>
                <TargetBankAccountForm />
              </Stack>
            </Stack>
          )}
      </EditFormContent>
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Não',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Sim',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleConfirmClick,
            },
          },
        ]}
      >
        Deseja criar uma nova conta?
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isTransferDialogOpen}
        title="Transferência"
        isLoading={isTransferring}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                dispatch(bankAccountActions.setIsTransferDialogOpen(false));
              },
            },
          },
          {
            name: 'Transferir',
            showLoading: true,
            buttonProps: {
              disabled: !isBalanceValid,
              variant: 'contained',
              color: 'primary',
              startIcon: <SendIcon />,
              onClick: () =>
                dispatch(
                  bankAccountActions.setIsTransferConfirmationOpen(true)
                ),
            },
          },
        ]}
      >
        <Stack direction="column" gap={2}>
          <FormControl sx={{ paddingLeft: 1 }}>
            <FormLabel id="labelTransferType" sx={{ fontSize: '14px' }}>
              Tipo de transferência
            </FormLabel>
            <RadioGroup
              name="transferType"
              value={transferType}
              onChange={(e) => setTransferType(e.target.value as 'pix' | 'ted')}
            >
              <FormControlLabel
                value="pix"
                control={<Radio size="small" />}
                label="PIX"
              />
              <FormControlLabel
                value="ted"
                control={<Radio size="small" />}
                label="TED"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            id="transferAmount"
            label="Valor"
            type="text"
            value={transferAmount}
            onChange={(e) => setTransferAmount(e.target.value)}
            error={!isBalanceValid}
            helperText={!isBalanceValid ? 'Insira um valor válido' : ''}
          />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            gap={1}
          >
            <Typography component="span">
              Saldo após transferência: {format(balanceAfterTransfer, 'money')}
            </Typography>
            <Typography variant="caption" component="span">
              (*)
            </Typography>
          </Box>
          <Typography variant="caption" component="span">
            (*) Tarifas podem ser aplicadas após a transferência
          </Typography>
        </Stack>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isTransferConfirmationOpen}
        title="Confirmação"
        isLoading={isTransferring}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                dispatch(
                  bankAccountActions.setIsTransferConfirmationOpen(false)
                );
              },
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleTransferConfirmClick,
            },
          },
        ]}
      >
        <Stack direction="column" gap={2}>
          <Typography>Verifique os dados antes de confirmar:</Typography>
          <Detail
            label="Tipo de transferência"
            value={transferType === 'pix' ? 'PIX' : 'TED'}
          />
          <Detail label="Valor" value={format(transferAmount, 'money')} />
          <Detail
            label="Saldo após transferência (*)"
            value={format(balanceAfterTransfer, 'money')}
          />
          <Typography variant="caption" component="span">
            (*) Tarifas podem ser aplicadas após a transferência
          </Typography>
        </Stack>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isInternalTransferDialogOpen}
        title="Transferência Interna"
        isLoading={isTransferring}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                dispatch(
                  bankAccountActions.setIsInternalTransferDialogOpen(false)
                );
              },
            },
          },
          {
            name: 'Transferir',
            showLoading: true,
            buttonProps: {
              disabled: !isBalanceValid,
              variant: 'contained',
              color: 'primary',
              startIcon: <SendIcon />,
              onClick: () =>
                dispatch(
                  bankAccountActions.setIsInternalTransferConfirmationOpen(true)
                ),
            },
          },
        ]}
      >
        <Stack direction="column" gap={2}>
          <TextField
            id="transferAmount"
            label="Valor"
            type="text"
            value={transferAmount}
            onChange={(e) => setTransferAmount(e.target.value)}
            error={!isBalanceValid}
            helperText={!isBalanceValid ? 'Insira um valor válido' : ''}
          />
          <TextField
            id="transferDescription"
            label="Descrição"
            type="text"
            value={transferDescription}
            onChange={(e) => setTransferDescription(e.target.value)}
            error={!isTransferDescriptionValid}
            helperText={
              !isTransferDescriptionValid ? 'A descrição é obrigatória' : ''
            }
          />
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            gap={1}
          >
            <Typography component="span">
              Saldo após transferência: {format(balanceAfterTransfer, 'money')}
            </Typography>
          </Box>
        </Stack>
      </ConfirmationDialog>
      {/* <ConfirmationDialog
        open={isInternalTransferConfirmationOpen}
        title="Confirmação"
        isLoading={isTransferring}
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                dispatch(
                  bankAccountActions.setIsInternalTransferConfirmationOpen(
                    false
                  )
                );
              },
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: handleInternalTransferConfirmClick,
            },
          },
        ]}
      >
        <Stack direction="column" gap={2}>
          <Typography>Verifique os dados antes de confirmar:</Typography>
          <Detail label="Valor" value={format(transferAmount, 'money')} />
          <Detail
            label="Saldo após transferência"
            value={format(balanceAfterTransfer, 'money')}
          />
        </Stack>
      </ConfirmationDialog> */}
      <ResponsiveDialog
        open={isPixLimitsFormOpen}
        maxWidth="sm"
        fullWidth
        onClose={() =>
          dispatch(bankAccountActions.setIsPixLimitsFormOpen(false))
        }
      >
        <PixLimits />
      </ResponsiveDialog>
    </EditForm>
  );
}
