import AddIcon from '@mui/icons-material/Add';
import { Button, Link } from '@mui/material';

import { GridEventListener } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import ResponsiveDialog from '../../../components/ResponsiveDialog/ResponsiveDialog';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getFirestoreConsoleLink } from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import useUserRole from '../../../hooks/useUserRole';
import { ListaUsuarios } from '../../../types';
import { settingsActions } from '../settings-slice';
import EditUser from './EditUser';

function UsersList() {
  const dispatch = useAppDispatch();
  const { isAdmin } = useUserRole();
  const {
    isLoadingSupplierUsers,
    supplierUserGroups,
    supplierUsers,
    isUserEditFormOpen,
  } = useAppSelector((state) => state.settings);
  const isDebugActivated = useAppSelector(
    (state) => state.devTools.isDebugActivated
  );

  const columns: TableColumn<ListaUsuarios>[] = [
    {
      id: 'id',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v) => (
        <>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            variant="caption"
            color="textSecondary"
            href={getFirestoreConsoleLink(`/users/${v}`)}
          >
            {v}
          </Link>
        </>
      ),
    },
    { id: 'name', label: 'Nome' },
    {
      id: 'idSupplierUserGroup',
      label: 'Grupo',
      formatter: (v) => {
        const group = supplierUserGroups.find(({ id }) => id === v);
        return group?.name ?? '-';
      },
    },
  ];

  const enhancedData: ListaUsuarios[] = [
    ...supplierUsers
      .filter(({ onboardingCompletedAt }) => onboardingCompletedAt)
      .map((c) => ({
        id: c.id,
        name: c.name ?? c.email,
        idSupplierUserGroup: c.idSupplierUserGroup,
      })),
  ].sort(({ name: a }, { name: b }) => a.localeCompare(b));

  useEffect(() => {
    dispatch(settingsActions.loadSupplierUsers());
  }, []);

  const addUserClick = () => {
    // navigate('novo');
    dispatch(settingsActions.setUserEditFormMode('new'));
    dispatch(settingsActions.setSelectedSupplierUser(undefined));
    dispatch(settingsActions.setIsUserEditFormOpen(true));
  };

  const handleRowClick: GridEventListener<'rowClick'> = ({ row }) => {
    const selected = supplierUsers.find(({ id }) => id === row.id);
    if (selected) {
      dispatch(settingsActions.setUserEditFormMode('edit'));
      dispatch(settingsActions.setSelectedSupplierUser(selected));
      dispatch(settingsActions.setIsUserEditFormOpen(true));
    }
  };

  return (
    <>
      <PageHeader
        title="Usuários"
        actions={
          <Button
            variant="contained"
            onClick={addUserClick}
            startIcon={<AddIcon />}
          >
            Novo
          </Button>
        }
      />
      <DataTable<ListaUsuarios>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingSupplierUsers}
        onRowClick={handleRowClick}
        hideFooter
        disableColumnMenu
      />
      <ResponsiveDialog
        open={isUserEditFormOpen}
        maxWidth={isAdmin ? 'sm' : 'xs'}
        fullWidth
        onClose={() => {
          dispatch(settingsActions.setIsUserGroupEditFormOpen(false));
        }}
      >
        <EditUser />
      </ResponsiveDialog>
    </>
  );
}

export default UsersList;
