import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Constants } from 'pr-constants';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import { CNAEField } from '../../../components/CNAEField/CNAEField';
import { CNPJField } from '../../../components/CNPJField/CNPJField';
import { CPFField } from '../../../components/CPFField/CPFField';
import { DateFieldWithControl } from '../../../components/DateField/DateFieldWithControl';
import FileUploadWithControl from '../../../components/FileUpload/FileUploadWithControl';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PhoneField } from '../../../components/PhoneField/PhoneField';
import { RadioGroupField } from '../../../components/RadioGroupField/RadioGroupField';
import { format } from '../../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import {
  getNumbersRegex,
  getValidAddressRegex,
  getValidCompanyRegex,
  getValidLettersRegex,
  testCNPJ,
  testCPF,
} from '../../../helpers/validations';
import { adminActions } from '../admin.slice';
import { AddSupplierForm } from '../admin.types';

function NovoFornecedor() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state) => state.admin);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<AddSupplierForm>({
    defaultValues: {
      name: '',
      fantasy: '',
      docNumber: '',
      email: '',
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressPostalCode: '',
      addressNeighborhood: '',
      addressCity: '',
      addressState: 'AC',
      cnae: '',
      gender: 'a',
      category: 'ltda',
      phone: '',
      foundationDate: '2010-01-01',
      repName: '',
      repIndividualDocumentNumber: '',
      repDocNumber: '',
      repDocNumberType: 'rg',
      repBirthDate: '1980-01-01',
      repPhone: '',
      repEmail: '',
      repIsPep: false,
      repMotherName: '',
      repMaritalStatus: 'single',
      repNationality: '',
      repAddressStreet: '',
      repAddressState: 'AC',
      repAddressCity: '',
      repAddressNeighborhood: '',
      repAddressNumber: '',
      repAddressPostalCode: '',
      repAddressComplement: '',
    },
  });

  const fantasy = watch('fantasy');
  const repDocNumberType = watch('repDocNumberType');

  const onSubmit = async (data: AddSupplierForm) => {
    //TODO: Remover campos de file
    const bankContractFile = data.bankContractFile as unknown as File;
    const repDocFile = data.repDocFile as unknown as File;
    const companyConstitutionDocFile =
      data.companyConstitutionDocFile as unknown as File;

    dispatch(
      adminActions.addSupplier({
        data: {
          ...data,
          cnae: format(data.cnae, 'cnae'),
        },
        bankContractFile,
        repDocFile,
        companyConstitutionDocFile,
        navigate,
      })
    );
  };

  return (
    <Stack direction="column" overflow="auto" gap={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader title="Novo Fornecedor" />
        <Grid2
          container
          sx={{ maxWidth: '1024px', marginX: 'auto' }}
          spacing={1}
        >
          <Grid2 xs={12}>
            <Typography variant="subtitle1">Dados Gerais</Typography>
          </Grid2>
          <Grid2 sm={12} md={6}>
            <TextField
              fullWidth
              id="name"
              label="Razão Social"
              type="text"
              {...register('name', {
                required: 'O nome é obrigatório',
                pattern: {
                  value: getValidCompanyRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.name}
              helperText={errors?.name?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <CNPJField
              fullWidth
              id="docNumber"
              name="docNumber"
              label="CNPJ"
              type="text"
              control={control}
              customValidate={(value) => {
                return testCNPJ(value) || 'CNPJ inválido';
              }}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <CNAEField
              fullWidth
              id="cnae"
              label="CNAE"
              name="cnae"
              type="text"
              control={control}
            />
          </Grid2>
          <Grid2 sm={12} md={6}>
            <TextField
              fullWidth
              id="fantasy"
              label="Nome Fantasia"
              type="text"
              {...register('fantasy', {
                required: 'O nome fantasia é obrigatório',
                pattern: {
                  value: getValidCompanyRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.fantasy}
              helperText={errors?.fantasy?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={6}>
            <RadioGroupField
              row
              name="gender"
              label="Como devemos nos referir à empresa?"
              options={[
                { label: `O ${fantasy}`, value: 'o' },
                { label: `A ${fantasy}`, value: 'a' },
              ]}
              control={control}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="company-category-label">
                Tipo de Empresa
              </InputLabel>
              <Select
                labelId="company-category-label"
                id="demo-simple-select-standard"
                label="Tipo de Empresa"
                defaultValue="ltda"
                {...register('category')}
              >
                {Object.entries(Constants.getCompanyCategoryMap()).map(
                  ([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 sm={12} md={3}>
            <DateFieldWithControl
              label="Data de Fundação"
              name="foundationDate"
              control={control}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <TextField
              fullWidth
              id="email"
              label="E-mail do usuário"
              type="text"
              {...register('email', {
                required: 'O email é obrigatório',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'E-mail inválido',
                },
              })}
              error={!!errors?.email}
              helperText={errors?.email?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <PhoneField
              fullWidth
              name="phone"
              id="phone"
              label="Telefone"
              type="text"
              control={control}
            />
          </Grid2>

          <Grid2 xs={12}>
            <Typography variant="subtitle1">Endereço</Typography>
          </Grid2>

          <Grid2 sm={12} md={6}>
            <TextField
              fullWidth
              id="addressStreet"
              label="Rua"
              type="text"
              {...register('addressStreet', {
                required: 'A rua é obrigatório',
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.addressStreet}
              helperText={errors?.addressStreet?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={2}>
            <TextField
              fullWidth
              id="addressNumber"
              label="Número"
              type="text"
              {...register('addressNumber', {
                required: 'O número é obrigatório',
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.addressNumber}
              helperText={errors?.addressNumber?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={4}>
            <TextField
              fullWidth
              id="addressComplement"
              label="Complemento"
              type="text"
              {...register('addressComplement', {
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.addressComplement}
              helperText={errors?.addressComplement?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <TextField
              fullWidth
              id="addressNeighborhood"
              label="Bairro"
              type="text"
              {...register('addressNeighborhood', {
                required: 'O bairro é obrigatório',
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.addressNeighborhood}
              helperText={errors?.addressNeighborhood?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <TextField
              fullWidth
              id="addressPostalCode"
              label="CEP (somente números)"
              type="text"
              {...register('addressPostalCode', {
                required: 'O CEP é obrigatório',
                pattern: {
                  value: getNumbersRegex(),
                  message: 'Somente números são aceitos',
                },
                validate: (v) => (v.length !== 8 ? 'CEP inválido' : true),
              })}
              error={!!errors?.addressPostalCode}
              helperText={errors?.addressPostalCode?.message}
            />
          </Grid2>

          <Grid2 sm={12} md={4}>
            <TextField
              fullWidth
              id="addressCity"
              label="Cidade"
              type="text"
              {...register('addressCity', {
                required: 'A cidade é obrigatória',
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.addressCity}
              helperText={errors?.addressCity?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="address-state-label">Estado</InputLabel>
              <Select
                labelId="address-state-label"
                id="demo-simple-select-standard"
                label="Estado"
                defaultValue="AC"
                {...register('addressState')}
              >
                {Constants.getStateList().map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 sm={12} md={6}>
            <FileUploadWithControl
              fullWidth
              variant="outlined"
              label="Contrato Social"
              name="companyConstitutionDocFile"
              control={control}
              rules={{
                required: 'O contrato social é obrigatório',
              }}
            />
          </Grid2>
          <Grid2 sm={12} md={6}>
            <FileUploadWithControl
              fullWidth
              variant="outlined"
              name="bankContractFile"
              label="Termo de abertura de conta"
              control={control}
              rules={{
                required: 'O termo de abertura de conta é obrigatório',
              }}
            />
          </Grid2>

          <Grid2 xs={12}>
            <Typography variant="subtitle1">Representante Legal</Typography>
          </Grid2>
          <Grid2 sm={12} md={6}>
            <TextField
              fullWidth
              id="repName"
              label="Nome"
              type="text"
              {...register('repName', {
                required: 'O nome é obrigatório',
                pattern: {
                  value: getValidLettersRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.repName}
              helperText={errors?.repName?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <CPFField
              fullWidth
              id="repIndividualDocumentNumber"
              name="repIndividualDocumentNumber"
              label="CPF"
              type="text"
              control={control}
              customValidate={(value, { type }) => {
                return type === 'pj' || testCPF(value) || 'CPF inválido';
              }}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <DateFieldWithControl
              label="Data de Nascimento"
              name="repBirthDate"
              control={control}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <RadioGroupField
              row
              name="repDocNumberType"
              label="Tipo de documento de identidade"
              options={[
                { label: `RG`, value: 'rg' },
                { label: `CNH`, value: 'cnh' },
              ]}
              control={control}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <TextField
              fullWidth
              id="repDocNumber"
              label={
                repDocNumberType === 'cnh'
                  ? 'CNH (somente números)'
                  : 'RG (somente números)'
              }
              type="text"
              {...register('repDocNumber', {
                required: 'A identidade é obrigatória',
                pattern: {
                  value: getNumbersRegex(),
                  message: 'Somente números são aceitos',
                },
              })}
              error={!!errors?.repDocNumber}
              helperText={errors?.repDocNumber?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={6}>
            <FileUploadWithControl
              fullWidth
              control={control}
              name="repDocFile"
              label={
                repDocNumberType === 'cnh'
                  ? 'CNH (PNG, JPG ou PDF)'
                  : 'RG (PNG, JPG ou PDF)'
              }
              rules={{
                required: 'O documento é obrigatório',
              }}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <TextField
              fullWidth
              id="repEmail"
              label="E-mail"
              type="text"
              {...register('repEmail', {
                required: 'O email é obrigatório',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'E-mail inválido',
                },
              })}
              error={!!errors?.repEmail}
              helperText={errors?.repEmail?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <PhoneField
              fullWidth
              name="repPhone"
              id="repPhone"
              label="Telefone"
              type="text"
              control={control}
            />
          </Grid2>
          <Grid2 sm={12} md={6}>
            <RadioGroupField
              row
              name="repIsPep"
              label="Pessoa publicamente exposta? (PEP)"
              options={[
                { label: 'Não', value: false },
                { label: 'Sim', value: true },
              ]}
              control={control}
            />
          </Grid2>
          <Grid2 sm={12} md={6}>
            <TextField
              fullWidth
              id="repMotherName"
              label="Nome da mãe"
              type="text"
              {...register('repMotherName', {
                required: 'O nome da mãe é obrigatório',
                pattern: {
                  value: getValidLettersRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.repMotherName}
              helperText={errors?.repMotherName?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="marital-status-label">Estado Civil</InputLabel>
              <Select
                labelId="marital-status-label"
                id="repMaritalStatus"
                label="Tipo de Empresa"
                defaultValue="single"
                {...register('repMaritalStatus')}
              >
                {Object.entries(Constants.getMaritalStatusMap()).map(
                  ([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 sm={12} md={3}>
            <TextField
              fullWidth
              id="repNationality"
              label="Nacionalidade"
              type="text"
              {...register('repNationality', {
                required: 'A nacionalidade é obrigatória',
                pattern: {
                  value: getValidLettersRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.repNationality}
              helperText={errors?.repNationality?.message}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant="subtitle1">Endereço</Typography>
          </Grid2>

          <Grid2 sm={12} md={6}>
            <TextField
              fullWidth
              id="repAddressStreet"
              label="Rua"
              type="text"
              {...register('repAddressStreet', {
                required: 'A rua é obrigatório',
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.repAddressStreet}
              helperText={errors?.repAddressStreet?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={2}>
            <TextField
              fullWidth
              id="repAddressNumber"
              label="Número"
              type="text"
              {...register('repAddressNumber', {
                required: 'O número é obrigatório',
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.repAddressNumber}
              helperText={errors?.repAddressNumber?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={4}>
            <TextField
              fullWidth
              id="repAddressComplement"
              label="Complemento"
              type="text"
              {...register('repAddressComplement', {
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.repAddressComplement}
              helperText={errors?.repAddressComplement?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <TextField
              fullWidth
              id="repAddressNeighborhood"
              label="Bairro"
              type="text"
              {...register('repAddressNeighborhood', {
                required: 'O bairro é obrigatório',
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.repAddressNeighborhood}
              helperText={errors?.repAddressNeighborhood?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={3}>
            <TextField
              fullWidth
              id="repAddressPostalCode"
              label="CEP (somente números)"
              type="text"
              {...register('repAddressPostalCode', {
                required: 'O CEP é obrigatório',
                pattern: {
                  value: getNumbersRegex(),
                  message: 'Somente números são aceitos',
                },
                validate: (v) => (v.length !== 8 ? 'CEP inválido' : true),
              })}
              error={!!errors?.repAddressPostalCode}
              helperText={errors?.repAddressPostalCode?.message}
            />
          </Grid2>

          <Grid2 sm={12} md={4}>
            <TextField
              fullWidth
              id="repAddressCity"
              label="Cidade"
              type="text"
              {...register('repAddressCity', {
                required: 'A cidade é obrigatória',
                pattern: {
                  value: getValidAddressRegex(),
                  message: 'Caracteres especiais não são aceitos',
                },
              })}
              error={!!errors?.repAddressCity}
              helperText={errors?.repAddressCity?.message}
            />
          </Grid2>
          <Grid2 sm={12} md={2}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="rep-address-state-label">Estado</InputLabel>
              <Select
                labelId="rep-address-state-label"
                id="demo-simple-select-standard"
                label="Estado"
                defaultValue="AC"
                {...register('repAddressState')}
              >
                {Constants.getStateList().map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          {Object.keys(errors).length > 0 && (
            <Grid2 xs={12}>
              <Alert severity="error">Corrija os erros para prosseguir</Alert>
            </Grid2>
          )}
        </Grid2>

        <Stack direction="row" gap={2} justifyContent="center" paddingY={4}>
          <NavLink to="..">
            <Button variant="outlined">Cancelar</Button>
          </NavLink>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
}

export default NovoFornecedor;
