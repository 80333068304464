import dayjs from 'dayjs';
import { Models } from 'types';
import { AddPaymentFormDetail } from '../features/supplier/supplier.types';
import { safeParseMaskedInput } from './safe-parse';

export type BalanceItem = {
  incomingDate: string;
  idReceivable: string;
  valueWithFine: number;
  balance: number;
  runningSum: number;
};

export type CalcBalanceOpts = {
  effectType: Models.PaymentRequestEffectType;
  fine: number;
  originalValue: number;
  values: Record<string, AddPaymentFormDetail>;
  paymentDueDate: string;
  monthlyInterest: number;
  firstAvailableDate: string;
};

type CalcBalanceResult = {
  balance: BalanceItem[];
  rollingBalance: number;
};

const safeRound = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const calcBalance = ({
  effectType,
  fine,
  originalValue = 0,
  values,
  paymentDueDate,
  // firstAvailableDate,
  monthlyInterest,
}: CalcBalanceOpts): CalcBalanceResult => {
  const finalFine = effectType === 'garantia' ? fine : 0;
  const fineValue = originalValue * (finalFine / 100);
  const dailyInterest = monthlyInterest / 30;

  const balance: BalanceItem[] = [];
  let runningSum = 0;
  let lastBalance: number | undefined = undefined;
  // let runningSuggestedSum = 0;
  // let lastSuggestedBalance: number | undefined = undefined;

  // console.log('values: ', values);
  // console.log('firstAvailableDate: ', firstAvailableDate);

  for (const v of Object.values(values)) {
    const daysSinceDueDate = dayjs(v.incomingDate).diff(paymentDueDate, 'days');
    const value = safeParseMaskedInput(v.value);
    // const availableValue = dayjs(v.incomingDate).isBefore(firstAvailableDate)
    //   ? 0
    //   : v.availableValue;

    runningSum += value;

    const valueWithFine: number = safeRound(
      lastBalance === undefined || lastBalance !== 0
        ? originalValue +
            fineValue +
            ((daysSinceDueDate * dailyInterest) / 100) * originalValue
        : 0
    );

    const currentBalance: number = safeRound(
      lastBalance !== 0 ? valueWithFine - runningSum : 0
    );

    // const suggestedCalc = availableValue - (lastSuggestedBalance ?? 0);
    // const suggestedValue =
    //   lastSuggestedBalance !== undefined
    //     ? suggestedCalc < 0
    //       ? availableValue
    //       : lastSuggestedBalance
    //     : Math.min(availableValue, valueWithFine);

    // runningSuggestedSum += suggestedValue;

    // const currentSuggestedBalance: number =
    //   lastSuggestedBalance !== 0 ? valueWithFine - runningSuggestedSum : 0;

    // console.log(
    //   `incomingDate: ${v.incomingDate} - value: ${value} - acc: ${runningSum} - days: ${daysSinceDueDate} - valueWithFine: ${valueWithFine} - balance: ${currentBalance} - lastBalance: ${lastBalance} - suggestedValue: ${suggestedValue}`
    // );
    // console.log(
    //   `incomingDate: ${
    //     v.incomingDate
    //   } - availableValue: ${availableValue} - valueWithFine: ${valueWithFine} - suggestedCalc: ${format(
    //     suggestedCalc,
    //     'money'
    //   )} - suggestedValue: ${format(
    //     suggestedValue,
    //     'money'
    //   )} - runningSuggestedSum: ${format(
    //     runningSuggestedSum,
    //     'money'
    //   )} - currentSuggestedBalance: ${format(
    //     currentSuggestedBalance,
    //     'money'
    //   )} - lastSuggestedBalance: ${format(lastSuggestedBalance, 'money')}`
    // );

    balance.push({
      incomingDate: v.incomingDate,
      idReceivable: v.idReceivable,
      // suggestedValue,
      valueWithFine,
      runningSum,
      balance: currentBalance,
    });

    lastBalance = currentBalance;
    // if (
    //   dayjs(v.incomingDate).isSame(firstAvailableDate) ||
    //   dayjs(v.incomingDate).isAfter(firstAvailableDate)
    // )
    //   lastSuggestedBalance = currentSuggestedBalance;
  }

  // console.log(
  //   'balance: ',
  //   balance.map(({ incomingDate, valueWithFine, balance }) => ({
  //     incomingDate,
  //     valueWithFine,
  //     dailyInterest,
  //     fineValue,
  //     balance,
  //   }))
  // );

  return {
    balance,
    rollingBalance: lastBalance ?? originalValue,
  };
};

// export const calcBalance = ({
//   effectType,
//   fine,
//   originalValue,
//   values,
//   paymentDueDate,
//   monthlyInterest,
// }: CalcBalanceOpts): CalcBalanceResult => {
//   const finalFine = effectType === 'garantia' ? fine : 0;
//   const valueWithFine = (originalValue ?? 0) * (1 + finalFine / 100);

//   const balance: BalanceItem[] = [];
//   let rollingBalance = valueWithFine;

//   const valuesGroupedByDay = values.reduce((acc, cur) => {
//     if (!acc[cur.incomingDate]) {
//       acc[cur.incomingDate] = 0;
//     }

//     const v = safeParseMaskedInput(cur.value);
//     acc[cur.incomingDate] += v;
//     return acc;
//   }, {} as Record<string, number>);

//   for (const v of [
//     ...Object.entries(valuesGroupedByDay).sort(
//       ([a], [b]) =>
//         dayjsBusinessTime(a).valueOf() - dayjsBusinessTime(b).valueOf()
//     ),
//   ]) {
//     const [incomingDate, val] = v;

//     const daysSinceDueDate = dayjsBusinessTime(incomingDate).diff(
//       dayjsBusinessTime(paymentDueDate),
//       'days'
//     );
//     if (daysSinceDueDate <= 0 || paymentDueDate === undefined) {
//       balance.push({
//         incomingDate: incomingDate,
//         balance: originalValue ?? 0,
//         suggestedValue: 0,
//       });
//       continue;
//     }

//     rollingBalance =
//       Math.floor(
//         (rollingBalance +
//           calcInterest(
//             rollingBalance,
//             daysSinceDueDate,
//             monthlyInterest / 100
//           ) -
//           val) *
//           100
//       ) / 100;
//     balance.push({
//       incomingDate: incomingDate,
//       balance: rollingBalance,
//       suggestedValue: 0,
//     });
//   }

//   return { balance, rollingBalance };
// };
