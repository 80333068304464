import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Models } from 'types';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { SwitchField } from '../../components/SwitchField/SwitchField';
import { TextFieldWithControl } from '../../components/TextField/TextFieldWithControl';
import { getFirestoreConsoleLink } from '../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import useUserRole from '../../hooks/useUserRole';
import FirebaseIcon from '../../icons/FirebaseIcon';
import { appActions } from '../app/app-slice';
import {
  SaveSupplierNotificationForm,
  SaveSupplierNotificationFormItem,
} from '../app/app.types';
import { settingsActions } from './settings-slice';

const WARRANTY_NOTIFICATIONS: Record<Models.WarrantyMailTemplateName, string> =
  {
    'retailer-warranty-awaiting-approval':
      'Varejista - Garantia aguardando aprovação',
    'retailer-warranty-created': 'Varejista - Garantia criada',
    'retailer-warranty-executed': 'Varejista - Garantia executada',
    'retailer-warranty-pending-execution':
      'Varejista - Aviso de execução de garantia',
    'supplier-warranty-approved': 'Fornecedor - Garantia aprovada',
    'supplier-warranty-not-approved': 'Fornecedor - Garantia não aprovada',
    'supplier-warranty-created': 'Fornecedor - Garantia criada',
    'retailer-warranty-canceled': 'Varejista - Garantia cancelada',
    'supplier-warranty-canceled': 'Fornecedor - Garantia cancelada',
    // 'supplier-warranty-executed': 'supplier-warranty-executed',
    // 'supplier-warranty-payment-conciliated':
    //   'supplier-warranty-payment-conciliated',
    // 'supplier-warranty-pending-execution':
    //   'supplier-warranty-pending-execution',
  };

// const OWNERSHIP_ASSIGNMENT_NOTIFICATIONS: Record<
//   Models.OwnershipAssignmentMailTemplateName,
//   string
// > = {
//   'retailer-ownership-assignment-awaiting-approval':
//     'retailer-ownership-assignment-awaiting-approval',
//   'retailer-ownership-assignment-created':
//     'retailer-ownership-assignment-created',
//   'retailer-ownership-assignment-executed':
//     'retailer-ownership-assignment-executed',
//   'supplier-ownership-assignment-approved':
//     'supplier-ownership-assignment-approved',
//   'supplier-ownership-assignment-created':
//     'supplier-ownership-assignment-created',
//   'supplier-ownership-assignment-payment-conciliated':
//     'supplier-ownership-assignment-payment-conciliated',
//   'supplier-ownership-assignment-payment-started':
//     'supplier-ownership-assignment-payment-started',
// };

const getTemplateCompanyType = (template: string) => template.split('-')[0];

export default function NotificationSettings() {
  const dispatch = useAppDispatch();
  const { isLoading, company } = useAppSelector((state) => state.app);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const { supplierNotificationConfig } = useAppSelector(
    (state) => state.settings
  );

  const defaultNotificationItems: SaveSupplierNotificationFormItem[] = [
    ...Object.entries(WARRANTY_NOTIFICATIONS).map(([template, label]) => {
      const existing = supplierNotificationConfig?.notificationItems.find(
        (i) => i.template === template
      );
      return {
        template: template as Models.MailTemplateName,
        emails:
          existing?.emails ??
          (getTemplateCompanyType(template) === 'supplier'
            ? company?.mainContactEmail ?? ''
            : ''),
        label,
        enabled: !!existing,
      };
    }),
    // ...Object.entries(OWNERSHIP_ASSIGNMENT_NOTIFICATIONS).map(
    //   ([template, label]) => {
    //     const existing = supplierNotificationConfig?.notificationItems.find(
    //       (i) => i.template === template
    //     );
    //     return {
    //       template: template as Models.MailTemplateName,
    //       emails: existing?.emails ?? '',
    //       label,
    //       enabled: !!existing,
    //     };
    //   }
    // ),
  ].sort(({ label: a }, { label: b }) => a.localeCompare(b));

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [formDataToSave, setFormDataToSave] = useState<
    SaveSupplierNotificationForm | undefined
  >(undefined);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { isAdmin } = useUserRole();
  const defaultValues = {
    notificationItems: defaultNotificationItems,
  };

  const {
    handleSubmit,
    formState: { isDirty },
    watch,
    reset,
    control,
  } = useForm<SaveSupplierNotificationForm>({ defaultValues });

  useEffect(() => {
    dispatch(settingsActions.loadSupplierNotificationConfig());
  }, []);

  useEffect(() => {
    if (supplierNotificationConfig) {
      reset(defaultValues);
    }
  }, [supplierNotificationConfig]);

  const notificationItems = watch('notificationItems');
  const enabledItems = notificationItems.filter(({ enabled }) => enabled);

  const onSubmit = async (data: SaveSupplierNotificationForm) => {
    // console.log('data: ', data);
    setFormDataToSave(data);
    setIsConfirmationOpen(true);
  };

  const onConfirmClick = () => {
    if (formDataToSave) {
      dispatch(settingsActions.saveSupplierNotificationConfig(formDataToSave));
      setIsConfirmationOpen(false);
    }
  };

  const handleCloseClick = () => {
    dispatch(appActions.setIsNotificationSettingsOpen(false));
    setIsConfirmationOpen(false);
  };

  return (
    <EditForm>
      <EditFormHeader
        title="Notificações"
        onCloseClick={handleCloseClick}
        actions={
          isDebugActivated && (
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href={getFirestoreConsoleLink(
                `/supplier-notification-config/${company?.id}`
              )}
            >
              <FirebaseIcon />
            </IconButton>
          )
        }
      />

      <EditFormContent display="flex" flexDirection="column" gap={2}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {!supplierNotificationConfig && !isFormOpen ? (
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={() => setIsFormOpen(true)}
              >
                Criar uma nova configuração
              </Button>
            ) : (
              <>
                {enabledItems.length === 0 && (
                  <Alert variant="outlined" severity="warning">
                    Atenção! Nenhuma notificação foi habilitada!
                  </Alert>
                )}

                <Grid2 container spacing={1}>
                  <Grid2 xs={12}>
                    <Divider />
                  </Grid2>
                  <Grid2 xs={12}>
                    <Typography variant="h6">Operação de Garantia</Typography>
                  </Grid2>
                  {defaultNotificationItems.map(
                    ({ template, label }, index) => {
                      const isNotificationEnabled = enabledItems.find(
                        (i) => i.template === template
                      );

                      const emailFieldLabel =
                        getTemplateCompanyType(template) === 'supplier'
                          ? isNotificationEnabled
                            ? 'Destinatários (separado por vírgula)'
                            : 'Notificação desabilitada'
                          : '-';
                      return (
                        <React.Fragment key={index}>
                          <Grid2 xs={6}>
                            <SwitchField
                              control={control}
                              name={`notificationItems.${index}.enabled`}
                              label={label}
                            />
                          </Grid2>
                          <Grid2 xs={6}>
                            <TextFieldWithControl
                              fullWidth
                              control={control}
                              name={`notificationItems.${index}.emails`}
                              label={emailFieldLabel}
                              disabled={
                                getTemplateCompanyType(template) !==
                                  'supplier' || !isNotificationEnabled
                              }
                            />
                          </Grid2>
                        </React.Fragment>
                      );
                    }
                  )}
                </Grid2>
              </>
            )}
          </>
        )}
      </EditFormContent>
      {isAdmin && (
        <EditFormAction
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingTop={2}
        >
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            disabled={!isDirty}
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Salvar
          </LoadingButton>
        </EditFormAction>
      )}
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Enviar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
              startIcon: <CheckIcon />,
              onClick: onConfirmClick,
            },
          },
        ]}
      >
        Deseja atualizar as configurações?
      </ConfirmationDialog>
    </EditForm>
  );
}
